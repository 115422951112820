import React, { useRef, useState, useEffect, useContext } from 'react';
import './signup.css';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { axiosInstance } from '../../config';
import { loginCall } from "../../apiCalls";
import { useParams } from 'react-router-dom';

export default function Registerpremium() {
  const usernameRef = useRef();
  const email = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [progress, setProgress] = useState(0);
  const { username: initialUsername } = useParams();
  const [username, setUsername] = useState(initialUsername || ''); // Use state to manage the username
  const { user, isFetching, dispatch, error } = useContext(AuthContext);


  useEffect(() => {
    if (initialUsername) {
      setUsername(initialUsername);
    }
  }, [initialUsername]);

  const handleChange = ({ currentTarget: input }) => {
    // Handle input changes
    // No need to use state for username, just update the ref
    usernameRef.current.value = input.value;
    email.current.value = input.value;

  };

  const sessionStripePlan = async (planId , userId) => {
    try {
      console.log('sessionStripePlan called with planId' , userId, planId);
      if (planId) {
        const { data: response } = await axiosInstance.post('/v1/stripe/subscribe/plan', { planId, userId: userId, email: email.current.value.toLowerCase() });
        console.log('sessionStripePlan called?', userId);

        window.location.href = response.url;
        console.log("window:", window.location.href);
      }
    } catch (error) {
        console.log(error);
    }
};

  // ... (your existing code)

const handleClick = async (e) => {
  e.preventDefault();

  const user = {
    username: usernameRef.current.value.toLowerCase(),
    email: email.current.value.toLowerCase(),
    password: password.current.value,
  };

  try {
    setProgress(50);

    // Make a POST request to your registration endpoint using Axios
    const res = await axiosInstance.post('/auth/register', user);

    // Check if the registration was successful
    if (res.status === 200) {
      // Extract user ID from the response
      const userId = res.data.data.userId; // Replace 'userId' with the actual key in your response

      // Set progress to 100% after successful registration
      setProgress(100);
      setMsg(res.data.message);

      // Check if the user is already logged in before making the login call
        // Use the user ID for further actions
        console.log('Newly registered user ID:', res.data.data.userId);

        // Make the login call using the extracted user ID
        await loginCall({ email: user.email, password: user.password }, dispatch);

        console.log('Login successful. Redirecting...');

        // Redirect or perform other actions after successful login
        sessionStripePlan('price_1ONoWHHcNBtzOtcBQODqRPHs', userId);

        console.log('past sessionStripPlan and email', email)

    } else {
      // Handle registration failure, e.g., display an error message to the user
      setMsg("Registration failed. Please try again later.");
    }
  } catch (err) {
    console.error(err);
    // Handle registration failure, e.g., display an error message to the user
    setMsg("Registration failed. Please try again later.");
  }
};

// ... (the rest of your existing code)

  


  return (
    <div className="register">
      <div className="registerWrapper">
        <div className="registerRight">
          <form className="registerBox" onSubmit={handleClick}>
          <Link to="/" className="logo link-style">
            <h3 className="registerLogo">LinkedX.io Premium</h3>
</Link>
            <span className="registerDesc">Sign up and start connecting!</span>
            <input
              maxLength="39"
              placeholder="Username"
              required
              ref={usernameRef}
              defaultValue={initialUsername}
              className="registerInput"
            />
            <input placeholder="Email" required ref={email} className="registerInput" type="email" />
            <input placeholder="Password" required ref={password} className="registerInput" type="password" minLength="3" />
            {/* <input placeholder="Password Again" required ref={passwordAgain} className="registerInput" type="password" minLength="3" /> */}
            {/* {msg && <div className="message">{msg}</div>} */}
            <span className="terms">
              By signing up, you agree to our Terms, Privacy Policy, and Cookies Policy.
            </span>
            <button className="registerButton" type="submit">
              Sign Up
            </button>
            {progress > 0 && (
              <div className="progress-circle-container">
                <CircularProgress color="primary" />
              </div>
            )}
            <span className="registerRegisterButton">
              Already have an account? <Link to="/login" style={{ textDecoration: 'none' }}>
                <span className="regbold">Log In</span>
              </Link>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
}
