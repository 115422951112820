import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../config';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Helmet from 'react-helmet';
import navlogomobile from './logosingle.png';
import { CircularProgress } from "@material-ui/core";
import './profile.css';

export default function Profile() {
  const [profileTitle, setProfileTitle] = useState('');
  const [verifiedStatus, setverifiedStatus] = useState('');
  const [profileBio, setProfileBio] = useState('');
  const [profilepic, setProfilePic] = useState('');
  const [backgroundpic, setBackgroundPic] = useState('');
  const [userLinks, setUserLinks] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [backgroundType, setBackgroundType] = useState('');
  const [user, setUser] = useState(null);
  const { username } = useParams();
  const lowercaseUsername = username.toLowerCase();
  const [premiumStatus, setpremiumStatus] = useState('');
  const [logo, setLogo] = useState();
  const [isProfilePicLoading, setIsProfilePicLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const backgroundStyle = {
    backgroundImage: `url(${backgroundpic})`,
    backgroundColor: backgroundColor,
    backgroundSize: 'cover',
  };

  const fetchUserData = async () => {
    try {
      setIsLoading(true); // Set loading to true before making asynchronous calls
  
      const userResponse = await axiosInstance.get(`/users?username=${lowercaseUsername}`);
      if (userResponse.data.length === 0) {
        setUser(null);
      } else {
        const userData = userResponse.data[0];
        setUser(userData);
  
        const profileDataResponse = await axiosInstance.get(`/users/profileTitleFetch?username=${lowercaseUsername}`);
        setProfileTitle(profileDataResponse.data.profileTitle);
        setverifiedStatus(userResponse.data.bluecheck);
        setpremiumStatus(userResponse.data.goldcheck);
        const bioDataResponse = await axiosInstance.get(`/users/profileBioFetch?username=${lowercaseUsername}`);
        setProfileBio(bioDataResponse.data.profileBio);
        setBackgroundColor(profileDataResponse.data.backgroundColor);
        const userLinksResponse = await axios.get(`https://linkedxio-e6f82560b206.herokuapp.com/api/userlinksFetch?username=${lowercaseUsername}`);
        setUserLinks(userLinksResponse.data.links);
        setLogo(profileDataResponse.data.logo);
        setBackgroundType(profileDataResponse.data.backgroundType);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false); // Set loading to false when all data has been fetched or in case of an error
    }
  };

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        setIsProfilePicLoading(true);
        const response = await axiosInstance.get(`/users/getusernameprofilepicture/${lowercaseUsername}`, { responseType: 'blob' });

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setProfilePic(url);
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      } finally {
        setIsProfilePicLoading(false);
      }
    };

    fetchProfilePic();
  }, [username, lowercaseUsername]);

  useEffect(() => {
    const fetchBackgroundPic = async () => {
      try {
        const response = await axiosInstance.get(`/users/getuserbackgroundpicture/${lowercaseUsername}`, { responseType: 'blob' });
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setBackgroundPic(url);
        }
      } catch (error) {
        console.error('Error fetching background picture:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBackgroundPic();
  }, [username, lowercaseUsername]);

  useEffect(() => {
    fetchUserData();
  }, [username, lowercaseUsername]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <CircularProgress size={50} style={{ color: 'white' }} />
      </div>
    );
  }

  return (
    <div className="profile-container1">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{profileTitle} | LinkedX</title>
        <meta property="og:image" content={profilepic} />
        <meta name="description" content="LinkedX. Make your one link rule them all." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${profileTitle} | LinkedX`} />
        <meta name="twitter:description" content="LinkedX. Make your one link rule them all" />
      </Helmet>
      {backgroundType === 'FlatColour' && (
        <div className="profile-container1" style={{ backgroundColor: backgroundColor }}>
          <section className="animated-background">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
          </section>

          <a id="profilePicture">
            {isProfilePicLoading ? (
              <CircularProgress size={30} style={{ color: 'white' }} />
            ) : profilepic ? (
              <img src={profilepic} alt="Profile" />
            ) : (
              <p className="profilenulltext">@</p>
            )}
          </a>

          <div className="overlay" id="popup">
            <div className="popup">
              <div className="popup-photo">
                <a href="https://avatars0.githubusercontent.com/u/43749971" target="_blank" rel="noopener noreferrer">
                  <img src="https://pbs.twimg.com/profile_images/1700343203616940032/Y0KMbn_z_400x400.jpg" alt="ProfilePicture" />
                </a>
              </div>
              <div className="popup-quote">We locked in.</div>
              <button className="popup-close" onClick={() => window.history.back()}>&times;</button>
            </div>
          </div>

          <div id="userName" className="userName">
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{profileTitle}</span>
            {verifiedStatus && (
              <img src="https://linkedx.io/bluecheck.svg" alt="Blue Check Mark" className="blue-check-mark" />
            )}
            {premiumStatus && (
              <img src="https://linkedx.io/goldcheck.png" alt="Gold Check Mark" className="gold-check-mark" />
            )}
          </div>
          <div id="userName" className="bioName">
            {profileBio}
          </div>

          <div id="links">
            {userLinks.map((link, index) => (
              <a
                key={index}
                className="link"
                href={link.url.startsWith('http://') || link.url.startsWith('https://') ? link.url : `http://${link.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`fab ${link.icon}`}>&nbsp;</i>
                {link.title}
              </a>
            ))}
          </div>
          <Link to="/home" style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!logo && (
              <button
                style={{
                  width: '235px',
                  cursor: 'pointer',
                  background: 'white',
                  border: 'none',
                  borderRadius: '30px',
                  color: 'black',
                  fontSize: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  padding: '16px 20px',
                  boxShadow: '0px 4px 1px rgba(0, 0, 0, 0.1)',
                }}
              >
                <img
                  className="navlogomobile2"
                  src={navlogomobile}
                  alt=""
                />
                Create Your LinkedX
              </button>
            )}
          </Link>
        </div>
      )}
      {backgroundType === 'Image' && backgroundpic && (
        <div className="profile-container1" style={backgroundStyle}>
          <section className="animated-background">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
          </section>
          <a id="profilePicture">
            {isProfilePicLoading ? (
              <CircularProgress size={30} style={{ color: 'white' }} />
            ) : profilepic ? (
              <img src={profilepic} alt="Profile" />
            ) : (
              <p className="profilenulltext">@</p>
            )}
          </a>

          <div id="userName" className="userName">
            {profileTitle}
            {verifiedStatus && (
              <img src="https://linkedx.io/bluecheck.svg" alt="Blue Check Mark" className="blue-check-mark" />
            )}
            {premiumStatus && (
              <img src="https://linkedx.io/goldcheck.png" alt="Gold Check Mark" className="gold-check-mark" />
            )}
          </div>
          <div id="userName" className="bioName">
            {profileBio}
          </div>

          <div id="links">
            {userLinks.map((link, index) => (
              <a
                key={index}
                className="link"
                href={link.url.startsWith('http://') || link.url.startsWith('https://') ? link.url : `http://${link.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`fab ${link.icon}`}>&nbsp;</i>
                {link.title}
              </a>
            ))}
          </div>

          <Link to="/home" style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!logo && (
              <button
                style={{
                  width: '235px',
                  cursor: 'pointer',
                  background: 'white',
                  border: 'none',
                  borderRadius: '30px',
                  color: 'black',
                  fontSize: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  padding: '16px 20px',
                  boxShadow: '0px 4px 1px rgba(0, 0, 0, 0.1)',
                }}
              >
                <img
                  className="navlogomobile2"
                  src={navlogomobile}
                  alt=""
                />
                Create Your LinkedX
              </button>
            )}
          </Link>
        </div>
      )}
    </div>
  );
}
