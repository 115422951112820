import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import LinkForm from '../linkform/Linkform';
import axios from 'axios';
import { faDroplet, faImage, faStar , faTimes} from '@fortawesome/free-solid-svg-icons';
import { axiosInstance } from '../../config';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Profile from '../profile/Profile';
import ProfilePreview from '../profilepreview/ProfilePreview';
import TopBar from '../../components/topbar/Topbar';
import TopBarMobile from '../../components/topbar/TopbarMobile';
import AddLinkButton from '../../components/addlinkbutton/Addlinkbutton';
import AddLinkPopup from '../../components/addlinkpopup/addlinkpopup';
import './admin.css';

export default function Adminpage() {
  document.title = "LinkedX.io Admin";
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState({ title: '', url: '' });
  const [user, setUser] = useState({});
  const { user: currentUser } = useContext(AuthContext);
  const [isAddLinkButtonVisible, setIsAddLinkButtonVisible] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showPreview, setShowPreview] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  const previewButton = (
    <button className="preview-button" onClick={togglePreview}>
      <span className="preview-text">Preview</span>
      {showPreview && (
        <span className="close-icon" onClick={closePreview}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      )}
    </button>
  );
  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
  };
  useLayoutEffect(() => {
    if (isMobile) {
      document.documentElement.style.overflowX = 'hidden';
    }

    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.documentElement.style.overflowX = 'visible';
    };
  }, [isMobile]);
  useEffect(() => {
    updateIsMobile(); // Call it initially
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);
  useEffect(() => {
    const fetchUser = async () => {
      const res = await axiosInstance.get(`/users?userId=${currentUser._id}`);
      setUser(res.data);
    };
    fetchUser();
  }, [currentUser._id]);

  const fetchUserLinks = async () => {
    try {
      const response = await axios.get(`https://linkedxio-e6f82560b206.herokuapp.com/api/userlinks?userId=${currentUser._id}`);
      if (response.status === 200) {
        // Sort the links based on the 'order' property
        const sortedLinks = response.data.sort((a, b) => a.order - b.order);
        setLinks(sortedLinks);
      } else {
        console.error('Failed to fetch user links');
      }
    } catch (error) {
      console.error('Error fetching user links:', error);
    }
  };


  useEffect(() => {
    // Call the fetchUserLinks function when the component mounts
    fetchUserLinks(currentUser._id);
  }, [currentUser._id, user._id]);

  const fetchUserLinksIfNeeded = async () => {
    try {
      const response = await axios.get(`https://linkedxio-e6f82560b206.herokuapp.com/api/userlinks?userId=${currentUser._id}`);
      if (response.status === 200) {
        // Sort the links based on the 'order' property
        const sortedLinks = response.data.sort((a, b) => a.order - b.order);
        setLinks(sortedLinks);
      } else {
        console.error('Failed to fetch user links');
      }
    } catch (error) {
      console.error('Error fetching user links:', error);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return; // No valid drop target, do nothing
    }

    // Reorder the links in state based on the drag-and-drop result
    const reorderedLinks = [...links];
    const [reorderedLink] = reorderedLinks.splice(result.source.index, 1);
    reorderedLinks.splice(result.destination.index, 0, reorderedLink);

    // Update the state with the reordered links
    setLinks(reorderedLinks);

    // Create an array of updated links with the new order values
    const updatedLinks = reorderedLinks.map((link, index) => ({
      userId: user._id, // Add userId to identify the user
      linkId: link._id, // Add linkId to identify the link
      order: index, // Update the order value
    }));

    // Send the updated order values to the server
    await updateOrderOnServer(updatedLinks);
  };

  const handleDeleteLink = async (linkId) => { // Add userId as a parameter
    try {
      // Make an API request to delete the link
      const response = await axios.delete(`https://linkedxio-e6f82560b206.herokuapp.com/api/deletelink/${currentUser._id}/${linkId}`); // Include userId in the URL
      console.log(linkId, "deleted");
  
      if (response.status === 200) {
        // Link deleted successfully, update the state to remove the link
        setLinks((prevLinks) => prevLinks.filter((link) => link._id !== linkId));
        
        // Fetch updated links after deleting a link
        fetchUserLinksIfNeeded(); // Use the fetchUserLinksIfNeeded function
      } else {
        console.error('Failed to delete link');
      }
    } catch (error) {
      console.error('Error deleting link:', error);
    }
  };
  

  const handleAddLinkClick = () => {
    setIsAddLinkButtonVisible(false); // Hide the button when clicked
  };

  const handleClosePopup = () => {
    setIsAddLinkButtonVisible(true); // Show the button when the popup is closed
  };

  const updateOrderOnServer = async (updatedLinks) => {
    try {
      // Make an API request to update the order values on the server
      const response = await axios.post('https://linkedxio-e6f82560b206.herokuapp.com/api/updateorder', updatedLinks);

      if (response.status === 200) {
        console.log('Order values updated on the server');
        // No need to fetch the links again as the order has been updated locally
      } else {
        console.error('Failed to update order values on the server');
      }
    } catch (error) {
      console.error('Error updating order values on the server:', error);
    }
  };

  const addLink = async () => {
    try {
      const response = await axios.post('https://linkedxio-e6f82560b206.herokuapp.com/api/addlink', newLink, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 201) {
        // Reset the newLink state
        setNewLink({ title: '', url: '' });
        
        // Fetch updated links after adding a new link
        fetchUserLinks();
      } else {
        console.error('Failed to add link');
      }
    } catch (error) {
      console.error('Error adding link:', error);
    }
  };
  

  return (
    <div>
    {isMobile ? (
      <TopBarMobile /> // Render TopBarMobile on mobile screens
    ) : (
      <TopBar /> // Render TopBar on other screens
    )}
      <div className="container">
        <div className="left">
          <div className="content">
            
              <div className="add-link-container">
      {isAddLinkButtonVisible ? (
        <AddLinkButton onClick={handleAddLinkClick} />
      ) : (
     <AddLinkPopup onClose={handleClosePopup} onLinkAdded={fetchUserLinks} />
      )}
            </div>

      <div>
         </div>
         {isAddLinkButtonVisible && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="links">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: 0 }}>
               {links.map((link, index) => (
  <Draggable key={link._id} draggableId={link._id} index={index}>
    {(provided) => (
      <li
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className="link-item"
      >
        <div className="link-container">
          <a
            href={link.url.startsWith('http://') || link.url.startsWith('https://') ? link.url : `http://${link.url}`}
            target="_blank"
            rel="noopener noreferrer"
            className="link-button"
          >
            {link.title}
          </a>
          <div className="delete-link-button-container">
            <button
              className="delete-link-button"
              onClick={() => handleDeleteLink(link._id)}
            >
              <FontAwesomeIcon icon={faTrash} />
              <span className="tooltip">Delete</span>
            </button>
          </div>
        </div>
      </li>
    )}
  </Draggable>
))}

                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
   
              </div>
       

         
        </div>
        {screenWidth > 768 && ( // Adjust the screen width condition as needed
          <div className="right">
            <div className="content">
              <div className="prevcontainer">
                <ProfilePreview />
              </div>
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="mobile-sticky-bar">
          {previewButton}
          {showPreview && <ProfilePreview />}
        </div>
      )}
      
    </div>
  );
}

