import React, { useContext, useState } from 'react';
import axios from 'axios';
import { RecoveryContext } from '../forgotpassword/Forgotpassword';
import { axiosInstance } from '../../config';
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const sectionStyle = {
  backgroundColor: '#f0f4f8',
  width: '100%',
};

const formContainerStyle = {
  background: 'white',
  padding: '24px',
  borderRadius: '8px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: '400px',
  margin: 'auto',
};

const titleStyle = {
  fontWeight: '600',
  fontSize: '24px',
  marginBottom: '16px',
  color: '#1a202c',
};

const labelStyle = {
  marginBottom: '8px',
  fontSize: '14px',
  fontWeight: '600',
  color: '#1a202c',
};

const inputStyle = {
  width: '92%',
  height: '48px',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid #d1d5db',
  fontSize: '14px',
  backgroundColor: 'white',
  outline: 'none',
  marginBottom: '16px',
};

const checkboxContainerStyle = {
  display: 'flex',
  alignItems: 'center',
};

const checkboxStyle = {
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  border: '1px solid #d1d5db',
  marginRight: '8px',
};

const buttonStyle = {
  width: '100%',
  height: '48px',
  borderRadius: '8px',
  border: 'none',
  fontSize: '16px',
  color: 'white',
  backgroundColor: '#2563eb',
  cursor: 'pointer',
  marginTop: '16px',
};

const Reset = () => {
  const { setPage , recoveryInfo } = useContext(RecoveryContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

console.log(recoveryInfo)
  const changePassword = async () => {
    // Perform client-side validation, for example, checking if passwords match
    if (password !== confirmPassword) {
      // Handle error (display a message to the user, etc.)
      console.error("Passwords don't match");
      return;
    }

    try {
      // Assuming you have an API endpoint for resetting the password
      const response = await axiosInstance.post('/reset-password', {
        email: recoveryInfo, // Provide the user's email
        newPassword: password,
      });

      // Handle the server response
      if (response.status === 200) {
        // Password reset successfully
        setPage('recovered');
      } else {
        // Handle other server response statuses
        console.error('Failed to reset password');
      }
    } catch (error) {
      // Handle request error
      console.error('Error resetting password:', error.message);
    }
  };

  return (
    <div style={containerStyle}>
      <section style={sectionStyle}>
        <div style={formContainerStyle}>
          <h2 style={titleStyle}>Change Password</h2>
          <form style={{ marginBottom: '16px' }}>
            <div>
              <label htmlFor="password" style={labelStyle}>
                New Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                style={inputStyle}
                onChange={(e) => setPassword(e.target.value)}
                required=""
              />
            </div>
            <div>
              <label htmlFor="confirm-password" style={labelStyle}>
                Confirm password
              </label>
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                placeholder="••••••••"
                style={inputStyle}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required=""
              />
            </div>
            <div style={checkboxContainerStyle}>
              {/* <div style={checkboxStyle}></div> */}
              {/* <div style={{ fontSize: '14px', color: '#718096' }}>
                I accept the{' '}
                <a href="#" style={{ color: '#2563eb', fontWeight: '600' }}>
                  Terms and Conditions
                </a>
              </div> */}
            </div>
          </form>
          <button onClick={changePassword} style={buttonStyle}>
            Reset password
          </button>
        </div>
      </section>
    </div>
  );
};

export default Reset;
