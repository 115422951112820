import { useState } from "react";
import { createContext } from "react";
import ResendOTP from "../OTPInput/Otp";
import Recovered from "../recovered/recovered";
import Reset from "../reset/Reset";
import LoginForgot from "../../pages/LoginForgot/LoginForgot";
export const RecoveryContext = createContext();

export default function ForgotPassword() {
  const [page, setPage] = useState("login");
  const [email, setEmail] = useState();
  const [otp, setOTP] = useState();
  const [isFetching, setIsFetching] = useState(false); // New state for loading indication
  const [recoveryInfo, setRecoveryInfo] = useState({ email: '' }); // Added state for recoveryInfo

  function NavigateComponents() {
    if (page === "login") return <LoginForgot />;
    if (page === "otp") return <ResendOTP />;
    if (page === "reset") return <Reset />;

    return <Recovered />;
  }

  
const containerStyle = {
    height: '100vh', // 100% of the viewport height
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <RecoveryContext.Provider
      value={{ page, setPage, otp, setOTP, setEmail, email, isFetching, setIsFetching,recoveryInfo , setRecoveryInfo }}
    >
      <div style={containerStyle}>
        <NavigateComponents />
      </div>
    </RecoveryContext.Provider>
  );
}
