import React, { useEffect, useState, useContext } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { logoutCall } from '../../apiCalls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../context/AuthContext';
import { faBolt, faLink, faPalette, faChartBar, faCog, faSignOutAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import { axiosInstance } from '../../config';
import { Link } from 'react-router-dom';
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './topbar.css'; // You can style this component

library.add(faLink, faPalette, faChartBar, faCog, faSignOutAlt);

const TopBar = () => {
  const { dispatch } = useContext(AuthContext);
  const [profilepic, setProfilePic] = useState(null);
  const { user: currentUser } = useContext(AuthContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userplan, setUserPlan] = useState({});

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        // Fetch the user's profile picture from your server
        const response = await axiosInstance.get(`/users/getuserprofilepicture/${currentUser._id}`, { responseType: 'blob' });
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setProfilePic(url);
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchProfilePic(); // Call the fetchProfilePicture function
  }, [currentUser._id, profilepic]);

  useEffect(() => {
    const fetchuserplan = async () => {
    try {
      const res = await axiosInstance.get(`/users/profileTitle?userId=${currentUser._id}`);
      const userData = res.data;
      setUserPlan(userData.userplan);
      console.log(userplan);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }    };

    fetchuserplan(); // Call the fetchProfilePicture function
  }, [userplan]);


  const handleClick = () => {
    logoutCall(dispatch);
  };

  return (
    <div className="top-bar">
      <div className="top-bar-labels">
        <Link to="/home" style={{ textDecoration: 'none' }}>
          <div className="top-bar-label logo-label">
            <img
              className="navlogomobile"
              src={navlogomobile}
              alt=""
            />
          </div>
        </Link>

        <Link to="/admin" style={{ textDecoration: 'none' }}>
          <div className="top-bar-label">
            <FontAwesomeIcon icon="link" /> Links
          </div>
        </Link>
        <Link to="/admin/appearance" style={{ textDecoration: 'none' }}>
          <div className="top-bar-label">
            <FontAwesomeIcon icon="palette" /> Appearance
          </div>
        </Link>
        <Link to={`/${currentUser.username}`} style={{ textDecoration: 'none' }}>
          <div className="top-bar-label">
            <FontAwesomeIcon icon={faEye} /> MyPage
          </div>
        </Link>
      </div>

      <div className="user-upgrade-container">
        <div className="user-profile-icon">
          {userplan === 'none' || userplan === 'Starter' ? (
            <Link to="/pricing" style={{ textDecoration: 'none' }}>
              <button className="upgrade-button">
                <FontAwesomeIcon icon={faBolt} /> Upgrade
              </button>
            </Link>
          ) : null}

          <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)} style={{ border: 'none' }}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-basic'>
              {profilepic ? (
                <img
                  src={profilepic}
                  alt="Profile"
                  style={{ border: 'none' }} // Remove the border
                />
              ) : (
                <img className='profilenulltext' src="http://linkedx.io/nullprofile.png" alt="" />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ backgroundColor: 'black' }}>
              <Dropdown.Item onClick={handleClick} className="white-logout-text">
                <FontAwesomeIcon icon="sign-out-alt" /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
