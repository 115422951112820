import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { logoutCall } from '../../apiCalls';
import { AuthContext } from '../../context/AuthContext';
import AnimatedPersonaProfile from '../../homeprofilepreview/Homeprofilepreview';
import PersonaProfile from '../../personaprofile/Personaprofile';
import CarolinaImage from './carolinapreview.jpg';
import SpiritImage from './spiritpreview.jpg';
import DomImage from './domlucrepreview.jpg';
import LauraLoomerImage from './lauraloomerpreview.jpg';
import ZubyImage from './zubymusicpreview.jpg';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../../components/footer/footer';
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './home.css';

export default function Home() {
  const { user: currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const history = useNavigate();
  const [username, setUsername] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isLoggedIn = !!currentUser;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const navigate = useNavigate();

  const imageUrls = [
    'https://via.placeholder.com/800x400?text=Image+1',
    'https://via.placeholder.com/800x400?text=Image+2',
    'https://via.placeholder.com/800x400?text=Image+3',
    // Add more image URLs as needed
  ];

  useEffect(() => {
    const carouselInner = document.querySelector('.carousel-inner');
    if (carouselInner) {
      carouselInner.style.transition = 'transform 0.5s ease';
      carouselInner.style.transform = `translateX(-${currentSlideIndex * 100}vw)`;
    }
  }, [currentSlideIndex]);
  
  // Start the automatic sliding interval
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 1000); // Adjust the interval duration in milliseconds as needed
  
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Run this effect once when the component mounts
  

  const handleClaimLink = () => {
    // Check if the username is not empty before navigating
    if (username.trim() === '') {
      // If empty, redirect to the signup page
      navigate('/signup');
    } else {
      // If not empty, proceed with the current behavior
      navigate(`/signup/user/${username}`);
    }
  };


  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handleClick = () => {
    if (username) {
      history(`/signup?username=${username}`);
    } else {
      // Handle the case where the username input is empty
    }
  };

  const [currentPersonaIndex, setCurrentPersonaIndex] = useState(0);

  const nextPersona = () => {
    setCurrentPersonaIndex((prevIndex) => (prevIndex + 1) % personaData.length);
  };

  const prevPersona = () => {
    setCurrentPersonaIndex((prevIndex) => (prevIndex - 1 + personaData.length) % personaData.length);
  };

  const personaData = [
    {
      
      image: DomImage,
    },
    {
      
      image: ZubyImage,
    },
      {
      image: SpiritImage,
    },


    // Add more persona data as needed
  ];

  const handleLogOutClick = () => {
    logoutCall(dispatch);
  };

  const [isMobile, setIsMobile] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
  };

  useEffect(() => {
    updateIsMobile(); // Call it initially
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return (

    
    <div className="home">
      <nav className={`sticky-navbar ${isMobile ? 'mobile-nav' : ''}`} style={{ zIndex: 100}}>
        <div className="topbar-items">
        <div className={`logo ${isMobile ? 'mobile-logo' : ''}${isMobile ? 'mobile-logo' : 'desktop-logo'}`}><Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
 <img
                className={`navlogo${isMobile ? 'mobile' : 'desktop'}`}
                src={isMobile ? navlogomobile : navlogodesktop}
                alt=""
              />  </Link></div>
  {/* <Link to="/pricing">
            <button className={`topbar-item`}>
              Team
            </button>
          </Link> */}
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Pricing
            </button>
          </Link>
          {/* <Link to="/pricing">
            <button className={`topbar-item`}>
            Discover
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
            Pricing
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Learn
            </button>
          </Link> */}
          </div>
        <div className="login-signup">
          {isLoggedIn ? (
            <button onClick={handleLogOutClick} className={`login`}>
              Log out
            </button>
          ) : (
            <Link to="/login">
              <button className={`login ${isMobile ? 'mobile-button' : ''}`}>
                Login
              </button>
            </Link>
          )}
          {isLoggedIn ? (
            <Link to="/admin">
              <button className={`signup`}>
                Admin
              </button>
            </Link>
          ) : (
            <div className="signup-input">
              <Link to="/signup">
                <button onClick={handleClick} className={`signup ${isMobile ? 'mobile-button' : ''}`}>
                  <span style={{ whiteSpace: 'nowrap' }}>{isMobile ? 'Sign Up' : 'Sign Up Free'}</span>
                </button>
              </Link>
            </div>
          )}
         
        </div>
      </nav>
          
  
        <div className="homecontainer">
          <div className="left">
            <div className="content">
              <div className="hero">
                <h1>Every link you need in one place.</h1>
                <p className='hero2'>
                  Join people using LinkedX.io for their link in bio. One link to help you share everything you create, curate, and sell  ensuring a space for those who may have been censored elsewhere.
                </p>
              </div>
              <div className="input-section">
                <div className="link-text-container">
                  <div className="link-text">linkedx.io/</div>
                  <div className="input-box">
                    <input
                      className={`yourname ${isMobile ? 'mobile-input' : ''}`}
                      type="text"
                      placeholder="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                </div>
                <button
              className={`claim-link ${isMobile ? 'mobile-button' : ''}`}
              onClick={handleClaimLink}
            >
              Claim Your LinkedX Now
            </button>              </div>
            </div>
          </div>
  
          {screenWidth > 768 && (
            <div className="right">
              <div className="content">
                <div className="persona-profile-container">
                  {personaData.map((data, index) => (
                    <AnimatedPersonaProfile
                      key={index}
                      isVisible={index === currentPersonaIndex}
                      personaData={personaData} 
                      style={{ zIndex: 0 }} // Set a lower zIndex for AnimatedPersonaProfile
                    />
                  ))}
                </div>
              </div>
            </div>
          )}


      </div>

      {isMobile && (
          <div className="left2">
          <div className="content">
            <div className="persona-profile-container">
              {personaData.map((data, index) => (
                <AnimatedPersonaProfile
                  key={index}
                  isVisible={index === currentPersonaIndex}
                  personaData={personaData} 
                  style={{ zIndex: 0 }} // Set a lower zIndex for AnimatedPersonaProfile

                />
              ))}
            </div>
          </div>
        </div>
      )}

<div className='webspace'>
  <div className="title">
    <h1>The only link in bio trusted by the Censored</h1>
    {/* <p className='subtitle'>
      conservatives
    </p> */}
  </div>

  <div className='slidewrap'>
    <div className='slider'>
      <div className='slide-track'>
        
      <div className='slide'>
  <a href="https://www.linkedx.io/donaldjtrumpjr" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/donaldtrumpjr.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/donaldjtrumpjr" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /donaldjtrumpjr
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/turningpointusa" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/tpusa.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/turningpointusa" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /tpusa
    </button>
      </a>
  </div>
</div>


      <div className='slide'>
  <a href="https://www.linkedx.io/omg" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/okeefemediagroup.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/omg" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /omg
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/jamesokeefeiii" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/jok.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/jamesokeefeiii" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /jamesokeefeiii
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/rogerjstonejr" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/rogerstone.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/rogerjstonejr" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /rogerjstonejr
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/givesendgo" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/givesendgo.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/givesendgo" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /givesendgo
    </button>
      </a>
  </div>
</div>

                {/* //double// */}
    
                <div className='slide'>
  <a href="https://www.linkedx.io/donaldjtrumpjr" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/donaldtrumpjr.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/donaldjtrumpjr" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /donaldjtrumpjr
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/turningpointusa" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/tpusa.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/turningpointusa" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /tpusa
    </button>
      </a>
  </div>
</div>


      <div className='slide'>
  <a href="https://www.linkedx.io/omg" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/okeefemediagroup.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/omg" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /omg
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/jamesokeefeiii" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/jok.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/jamesokeefeiii" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /jamesokeefeiii
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/rogerjstonejr" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/rogerstone.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/rogerjstonejr" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /rogerjstonejr
    </button>
      </a>
  </div>
</div>

<div className='slide'>
  <a href="https://www.linkedx.io/givesendgo" target="_blank" rel="noopener noreferrer">
    <img className='slideimg' src="https://linkedx.io/givesendgo.png" alt="" />
  </a>
  <div className='slideimg-container'>
      <a href="https://www.linkedx.io/givesendgo" target="_blank" rel="noopener noreferrer">
    <button className='slide-button'>
        <img className='logobutton' src="https://linkedx.io/favicon.png" alt="" />
        /givesendgo
    </button>
      </a>
  </div>
</div>
                
            
      </div>
    </div>
  </div>
</div>
<Footer/>

    </div>
  );
           }