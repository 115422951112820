import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useProSidebar } from 'react-pro-sidebar';
import { useTheme } from '@emotion/react';
//import { userProfileAction } from '../redux/actions/userAction';






const HeaderTop = () => {

    const { collapseSidebar } = useProSidebar();
    // React.useEffect(() => {
    //     dispatch(userProfileAction());
    // }, [])

    return (

        <Box display="flex" justifyContent="space-between" p={2}>
            <Box display="flex">
                <IconButton onClick={() => collapseSidebar()}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2, color: 'blue' }}
                >
                    <MenuIcon />
                </IconButton>

                {/* <Box
                    display="flex"
                    backgroundColor="white"
                    borderRadius="3px"
                >
                    <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon sx={{ color: 'blue' }} />
                    </IconButton>
                </Box> */}
            </Box>
        </Box>

    );
}

export default HeaderTop;