import { Box, Container, Grid, CircularProgress } from "@mui/material"; // Import CircularProgress
import Pricing from "../../components/pricing/Pricing";
import axios from 'axios';
import { axiosInstance } from "../../config";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import PriceComparison from "../../components/pricecompare/PriceComparison";
import { AuthContext } from "../../context/AuthContext";
import { Link } from 'react-router-dom';
import { logoutCall } from '../../apiCalls';
import Footer from "../../components/footer/footer";
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './plan.css'

const Plan = () => {
    const { user: currentUser } = useContext(AuthContext);
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const { dispatch } = useContext(AuthContext);
    const history = useNavigate();
    const isLoggedIn = !!currentUser;
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
    };

    const handleClick = () => {
        if (username) {
          history(`/signup?username=${username}`);
        } else {
          // Handle the case where the username input is empty
        }
      };

      const handleLogOutClick = () => {
        logoutCall(dispatch);
        window.location.reload();
      };
  
    useEffect(() => {
      updateIsMobile(); // Call it initially
      window.addEventListener('resize', updateIsMobile);
      return () => {
        window.removeEventListener('resize', updateIsMobile);
      };
    }, []);
    const fetchPriceList = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/v1/stripe/priceslist');
            const responseData = response.data; // Extract response data
    
            if (responseData && responseData.data) {
                // If data is available, setPlans with reversed data
                setPlans(responseData.data.reverse());
                console.log(responseData.data);
            } else {
                console.error('Invalid response format:', response);
            }
    
            setLoading(false);
        } catch (error) {
            console.error('Error fetching price list:', error);
            console.log(error.response.data.error);
            setLoading(false);
        }
    };    

    useEffect(() => {
        fetchPriceList();
    }, []);

    return (
        <>
        <nav className={`sticky-navbar2 ${isMobile ? 'mobile-nav' : ''}`} style={{ zIndex: 100 }}>
        <div className="topbar-items">
        <div className={`logo ${isMobile ? 'mobile-logo' : ''}${isMobile ? 'mobile-logo' : 'desktop-logo'}`}><Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img
                className={`navlogo${isMobile ? 'mobile' : 'desktop'}`}
                src={isMobile ? navlogomobile : navlogodesktop}
                alt=""
              />
  </Link></div>
          {/* <Link to="/pricing">
            <button className={`topbar-item`}>
              Team
            </button>
          </Link> */}
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Pricing
            </button>
          </Link>
          {/* <Link to="/pricing">
            <button className={`topbar-item`}>
            Discover
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
            Pricing
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Learn
            </button>
          </Link> */}
        </div>
        <div className="login-signup">
          {isLoggedIn ? (
            <button onClick={handleLogOutClick} className={`login`}>
              Log Out
            </button>
          ) : (
            <Link to="/login">
              <button className={`login ${isMobile ? 'mobile-button' : ''}`}>
                Login
              </button>
            </Link>
          )}
          {isLoggedIn ? (
            <Link to="/admin">
              <button className={`signup`}>
                Admin
              </button>
            </Link>
          ) : (
            <div className="signup-input">
              <Link to="/signup">
                <button onClick={handleClick} className={`signup ${isMobile ? 'mobile-button' : ''}`}>
                  <span style={{ whiteSpace: 'nowrap' }}>{isMobile ? 'Sign Up' : 'Sign Up Free'}</span>
                </button>
              </Link>
            </div>
          )}
         
        </div>
      </nav>
            <Box sx={{ minHeight: 'calc(100vh - 165px)', pt: 5 }}>
                {loading ? (
                    // Use CircularProgress for loading state
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Container maxWidth="md" component="main">
                        <Grid container spacing={5} alignItems="flex-end">
                            {plans && plans.map((plan, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={4}
                                >
      <Pricing plan={{ ...plan, nickname: index === 0 ? 'Starter' : index === 1 ? 'Pro' : 'Premium'}} />
                                </Grid>
                            ))}
                        </Grid>

                        {/* Price comparison */}
                        <Box sx={{ pb: 10 }}>
                            <PriceComparison />
                        </Box>
                    </Container>
                )}
            </Box>
            <Footer/>
        </>
    )
}

export default Plan;
