import React, { useContext , useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { axiosInstance } from '../../config';
import Chip from '@mui/material/Chip';
import { AuthContext } from '../../context/AuthContext';

const Pricing = ({ plan }) => {
    const location = useLocation();
    const { user: currentUser } = useContext(AuthContext);
    const [user, setUser] = useState({});

   useEffect(() => {
  const fetchUser = async () => {
    try {
      const res = await axiosInstance.get(`/users?userId=${currentUser._id}`);
      setUser(res.data);
      console.log("Current Path:", location.pathname , plan.nickname, res.data.plan);
    } catch (error) {
      console.error(error);
    }
  };
  fetchUser();
}, []);

    const dynamicDescription = () => {
        if (plan.nickname === 'Starter') {
            return 'Upgraded style options';
        } else if (plan.nickname === 'Pro') {
            return 'Advanced customization options';
        } else if (plan.nickname === 'Premium') {
            return 'Exclusive content';
        }
    };

    const sessionStripePlan = async (planId) => {
        try {
            if (planId) {
                const { data: response } = await axiosInstance.post('/v1/stripe/subscribe/plan', { planId, userId: currentUser._id , email: user.email});
                window.location.href = response.url;
                console.log("window:" , window.location.href);

            }
        } catch (error) {
            console.log(error);
        }
    };

    const manageSubscription = async () => {
        try {
            const { data } = await axiosInstance.post('/v1/stripe/managesubscription', { userId: currentUser._id });
            window.location.href = data;
            console.log("Manage Subscription Called and window data=", data);
        } catch (error) {
            console.error(error);
        }
    };

    const controlOutputTextButton = () => {
        console.log("Condition:", window.location.pathname === '/user/plan' && user && user.plan === 'Starter');
        if (location.pathname === '/user/plan') {
            return (
                <Button
                    disabled={user}
                    sx={{ borderRadius: '25px' }}
                    disableElevation={true}
                    fullWidth
                    variant={plan && user.plan === 'Pro' ? 'contained' : 'outlined'}
                >
                    <Link to="/register" style={{ color: plan && plan.nickname === 'Pro' ? 'white' : 'blue', textDecoration: 'none' }}>
                        7 days free
                    </Link>
                </Button>
            );
        } else if (location.pathname === '/user/plan' && user && user.plan === 'Starter') {
            return (
                <Button onClick={() => manageSubscription()} sx={{ borderRadius: '25px' }} disableElevation={true} fullWidth variant="outlined">
                    Manage subscription
                </Button>
            );
        } else if (location.pathname === '/user/plan' && user && user.plan === 'Pro') {
            return (
                <Button onClick={() => manageSubscription()} sx={{ borderRadius: '25px' }} disableElevation={true} fullWidth variant="outlined">
                    Manage subscription
                </Button>
            );
        } else if (location.pathname === '/user/plan' && user && user.plan === 'Premium') {
            return (
                <Button onClick={() => manageSubscription()} sx={{ borderRadius: '25px' }} disableElevation={true} fullWidth variant="outlined">
                    Manage subscription
                </Button>
            );
        }
    };

    const getDescriptionByPlan = (plan) => {
        switch (plan.nickname) {
          case 'Starter':
            return (
              <>
                <Typography component="li" variant="subtitle1" align="center">
                Upgraded style options
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                  
                </Typography>
                {/* Add more Starter-specific descriptions as needed */}
              </>
            );
          case 'Pro':
            return (
              <>
              <Typography component="li" variant="subtitle1" align="center">
              Everything in Starter, plus:
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                  Blue Checkmark
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                Advanced customization
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                Option to hide LinkedX logo
                </Typography>
                {/* Add more Pro-specific descriptions as needed */}
              </>
            );
          case 'Premium':
            return (
              <>
              <Typography component="li" variant="subtitle1" align="center">
              Everything in Pro, plus:
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                 Gold Checkmark
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                Exclusive content
                </Typography>
                {/* Add more Premium-specific descriptions as needed */}
              </>
            );
          default:
            return null; // Handle unknown plans or provide a default description
        }
      };
    

    return (
        <>
            <Card sx={{ borderRadius: '25px' }}>
                <Box sx={{ position: 'relative' }}>
                    <Chip
                        sx={{
                            display: user && user.plan === plan && plan.nickname ? 'block' : 'none',
                            position: 'absolute',
                            right: '2px',
                            height: '20px',
                            background: 'forestgreen',
                            border: '1px solid white',
                            top: '3px',
                            fontSize: '12px',
                            color: 'white',

                        }}
                        label="Active plan"
                        color="success"
                        variant="outlined"
                    />
                </Box>
                <CardHeader
                    title={plan.nickname}
                    subheader={plan.nickname === 'Pro' ? 'Most Popular' : ''}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{
                        align: 'center',
                        color: '#fafafa',
                        
                    }}
                    sx={{
                        bgcolor: plan.nickname === 'Pro' ? '#007bff' : '#BBBEAC', // Adjust the colors as needed
                        color: plan.nickname === 'Pro' ? '#fafafa' : 'black', // Adjust the colors as needed
            
                    }}
                />
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            mb: 2,
                        }}
                    >
                        <Typography component="h2" variant="h4" color="text.primary">
                            US${plan.unit_amount / 100}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                            /month
                        </Typography>
                    </Box>
                    <Typography component="ul" sx={{ listStyle: 'none', padding: 0, margin: 'auto' }}>
                    {getDescriptionByPlan(plan)}
                    </Typography>
                </CardContent>
                <CardActions>
        {user && user.plan === plan?.nickname ? (
          <Button sx={{ borderRadius: '25px' }} disableElevation={true} fullWidth variant="contained" color="success">
            Current plan
          </Button>
        ) : (
            <Button
  onClick={() => sessionStripePlan(plan.id)}
  sx={{ borderRadius: '25px' }}
  disableElevation={true}
  fullWidth
  variant={plan && plan.nickname === 'Pro' ? 'contained' : 'outlined'}
  component={currentUser ? 'button' : Link}
  to={
    currentUser
      ? null
      : plan && plan.nickname === 'Pro'
      ? '/signup/pro'
      : plan && plan.nickname === 'Premium'
      ? '/signup/premium'
      : '/signup/starter'
  }
>
  {currentUser ? 'Get this plan' : 'Get this plan'}
</Button>
        )}
        {controlOutputTextButton()}
      </CardActions>

            </Card>
        </>
    );
};

export default Pricing;
