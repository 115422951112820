import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/footer';
import { logoutCall } from '../../apiCalls';
import { AuthContext } from '../../context/AuthContext';
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './termsandconditions.css'; // Add your CSS file for styling

export default function Terms() {
    const { user: currentUser } = useContext(AuthContext);
    const isLoggedIn = !!currentUser;
    const history = useNavigate();
    const [username, setUsername] = useState('');
    const { dispatch } = useContext(AuthContext);
  
    const handleLogOutClick = () => {
        logoutCall(dispatch);
    };

    const handleClick = () => {
        if (username) {
            history(`/signup?username=${username}`);
        } else {
            // Handle the case where the username input is empty
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
    };
    useEffect(() => {
        updateIsMobile(); // Call it initially
        window.addEventListener('resize', updateIsMobile);
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };
    }, []);

    return (
        <div className="about">
            {/* Include your top bar component */}
            <nav className={`sticky-navbar ${isMobile ? 'mobile-nav' : ''}`} style={{ zIndex: 100 }}>
                <div className="topbar-items">
                    <div className={`logo ${isMobile ? 'mobile-logo' : ''}${isMobile ? 'mobile-logo' : 'desktop-logo'}`}>
                        <Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <img
                                className={`navlogo${isMobile ? 'mobile' : 'desktop'}`}
                                src={isMobile ? navlogomobile : navlogodesktop}
                                alt=""
                            />
                        </Link>
                    </div>
                    <Link to="/pricing">
                        <button className={`topbar-item`}>
                            Pricing
                        </button>
                    </Link>
                </div>
                <div className="login-signup">
                    {isLoggedIn ? (
                        <button onClick={handleLogOutClick} className={`login`}>
                            Log out
                        </button>
                    ) : (
                        <Link to="/login">
                            <button className={`login ${isMobile ? 'mobile-button' : ''}`}>
                                Login
                            </button>
                        </Link>
                    )}
                    {isLoggedIn ? (
                        <Link to="/admin">
                            <button className={`signup`}>
                                Admin
                            </button>
                        </Link>
                    ) : (
                        <div className="signup-input">
                            <Link to="/signup">
                                <button onClick={handleClick} className={`signup ${isMobile ? 'mobile-button' : ''}`}>
                                    <span style={{ whiteSpace: 'nowrap' }}>{isMobile ? 'Sign Up' : 'Sign Up Free'}</span>
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
            </nav>
            <div className="about-container">
                <div className="about-content">
                    <h1 className='hero1'>Terms and Conditions</h1>
                    <p className='hero3'>Last updated: Sunday Jan 7, 2024</p>

                    <p className='hero3'>
                        1. Welcome to LinkedX:
                        Terms govern the use of Linkedx services.
                        Users must be at least 18 years old.
                        Users agree to comply with the terms.
                    </p>

                    <p className='hero3'>
                        2. Changes to Terms:
                        LinkedX may change terms to reflect updates, legal reasons, etc.
                        Material changes will be notified in advance.
                    </p>

                    <p className='hero3'>
                        3. Your Account:
                        Users must be 18 or have permission.
                        Users are responsible for account security.
                        Accounts can't be transferred without permission.
                    </p>

                    <p className='hero3'>
                        4. Your Username:
                        Usernames must respect rights and avoid infringement.
                        Offensive or vulgar usernames are not allowed.
                    </p>

                    <p className='hero3'>
                        5. Managing Your Plan:
                        Users can have free or paid plans.
                        Cancel anytime; paid plan fees are non-refundable.
                        Don't share login details.
                    </p>

                    <p className='hero3'>
                        6. Your Content:
                        Users are responsible for their content.
                        Content must not infringe on rights or harm reputation.
                        Follow community standards.
                    </p>

                    <p className='hero3'>
                        7. What we can do with Your Content:
                        Linkedx has a license to use and display user content.
                        Users retain rights in their content.
                    </p>

                    <p className='hero3'>
                        8. Suspension or Cancellation of Your Account:
                        LinkedX can suspend/cancel accounts for non-compliance.
                        No refund for fees paid in advance.
                    </p>

                    <p className='hero3'>
                        9. Your Responsibility for Your Visitors and Customers:
                        Users are responsible for profile visitors and transactions.
                        Donations through "Support Me" are voluntary.
                    </p>

                    <p className='hero3'>
                        10. Feedback:
                        Users can provide feedback; LinkedX may use it.
                    </p>

                    <p className='hero3'>
                        11. Our Platform:
                        Limited right to use the platform.
                        LinkedX IP is owned by LinkedX.
                        Restrictions on using platform content.
                    </p>

                    <p className='hero3'>
                        12. Fees and Payment:
                        Subscription fees, transaction fees, and processing fees may apply.
                        Users are responsible for external fees and taxes.
                        Linkedx may adjust fees with notice.
                    </p>

                    <p className='hero3'>
                        13. Privacy:
                        Privacy Notice outlines how personal data is handled.
                        Users confirm understanding and consent to Data Processing Addendum.
                    </p>

                    <p className='hero3'>
                        14. Confidentiality:
                        Users must keep confidential information secure.
                    </p>

                    <p className='hero3'>
                        15. Liability:
                        Users are responsible for platform use and content.
                        LinkedX is not liable for damages.
                    </p>

                    <p className='hero3'>
                        16. Disclaimers:
                        Platform provided "AS IS" and "AS AVAILABLE."
                    </p>

                    <p className='hero3'>
                        17. Third Party Services:
                        LinkedX collaborates with third-party services.
                        Users are responsible for third-party terms.
                    </p>

                    <p className='hero3'>
                        18. App Terms:
                        Specific terms for using the Linkedx app.
                    </p>

                    <p className='hero3'>
                        19. Open Source:
                        The app includes open-source software components with specific license terms.
                    </p>

                    <p className='hero3'>
                        20. A Few Last Things:
                        Certain laws may apply depending on location.
                        These terms constitute the full agreement.
                        Terms are subject to the laws of the Commonwealth of United States.
                        Users must adhere to YouTube’s Terms of Service when using Music Link or Video Link features.
                    </p>

                    <h2 className='hero1'>Where can you get further information?</h2>
                    <p className='hero3'>If you have any questions about our Terms and Conditions or other matters, please email us at <a className="hrefmail" href="mailto:support@linkedx.io">support@linkedx.io</a>.</p>
                </div>
            </div>

            <Footer />
        </div>
    );
}