import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/footer';
import { logoutCall } from '../../apiCalls';
import { AuthContext } from '../../context/AuthContext';
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './about.css'; // Add your CSS file for styling

export default function About() {
    const { user: currentUser } = useContext(AuthContext);
    const isLoggedIn = !!currentUser;
    const history = useNavigate();
    const [username, setUsername] = useState('');
    const { dispatch } = useContext(AuthContext);
  
    const handleLogOutClick = () => {
        logoutCall(dispatch);
      };
      const handleClick = () => {
        if (username) {
          history(`/signup?username=${username}`);
        } else {
          // Handle the case where the username input is empty
        }
      };

      const [isMobile, setIsMobile] = useState(false);

      const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
      };
      useEffect(() => {
        updateIsMobile(); // Call it initially
        window.addEventListener('resize', updateIsMobile);
        return () => {
          window.removeEventListener('resize', updateIsMobile);
        };
      }, []);
    
  return (
    <div className="about">
      {/* Include your top bar component */}
      <nav className={`sticky-navbar ${isMobile ? 'mobile-nav' : ''}`} style={{ zIndex: 100}}>
        <div className="topbar-items">
        <div className={`logo ${isMobile ? 'mobile-logo' : ''}${isMobile ? 'mobile-logo' : 'desktop-logo'}`}><Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
 <img
                className={`navlogo${isMobile ? 'mobile' : 'desktop'}`}
                src={isMobile ? navlogomobile : navlogodesktop}
                alt=""
              />  </Link></div>
  {/* <Link to="/pricing">
            <button className={`topbar-item`}>
              Team
            </button>
          </Link> */}
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Pricing
            </button>
          </Link>
          {/* <Link to="/pricing">
            <button className={`topbar-item`}>
            Discover
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
            Pricing
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Learn
            </button>
          </Link> */}
          </div>
        <div className="login-signup">
          {isLoggedIn ? (
            <button onClick={handleLogOutClick} className={`login`}>
              Log out
            </button>
          ) : (
            <Link to="/login">
              <button className={`login ${isMobile ? 'mobile-button' : ''}`}>
                Login
              </button>
            </Link>
          )}
          {isLoggedIn ? (
            <Link to="/admin">
              <button className={`signup`}>
                Admin
              </button>
            </Link>
          ) : (
            <div className="signup-input">
              <Link to="/signup">
                <button onClick={handleClick} className={`signup ${isMobile ? 'mobile-button' : ''}`}>
                  <span style={{ whiteSpace: 'nowrap' }}>{isMobile ? 'Sign Up' : 'Sign Up Free'}</span>
                </button>
              </Link>
            </div>
          )}
         
        </div>
      </nav>
      <div className="about-container">
        <div className="about-content">
          <h1 className='hero1'>Our Founders' Story</h1>
          <p className='hero3'>
          In 2021, Spirit encountered the stifling grip of censorship while sharing his content on mainstream social media platforms. Recognizing the imperative of a liberated space for expression, he drew inspiration from tech luminaries Elon Musk and Chris Pavloski. Fueled by a passion to defy limitations, Spirit took decisive action, launching an alternative platform that not only safeguards creators from censorship but also streamlines the sharing process with simplified links. His journey embodies the resilience of those who refuse to be silenced, carving a path towards a digital realm where freedom of expression reigns supreme.
</p>
        </div>
      </div>

      {/* Include your footer component */}
      <Footer />
    </div>
  );
}
