import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { axiosInstance } from '../../config';
import axios from 'axios';
import './profilepreview.css';

export default function ProfilePreview() {
  const [profileTitle, setProfileTitle] = useState('');
  const [profileBio, setProfileBio] = useState('');
  const [profilepic, setProfilePic] = useState(null)
  const [backgroundpic, setBackgroundPic] = useState();
  const [userLinks, setUserLinks] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState('#fff'); // Initialize background color
  const [backgroundType, setBackgroundType] = useState(''); // Initialize background type
  const [verifiedStatus, setverifiedStatus] = useState('');
  const [premiumStatus, setpremiumStatus] = useState('');
  const [userplan, setUserPlan] = useState({});
  const [logo, setLogo] = useState(); // Initial value, assuming initialLogoValue is a boolean


  const { user: currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        // Fetch the user's profile picture from your server
        const response = await axiosInstance.get(`/users/getuserprofilepicture/${currentUser._id}`, { responseType: 'blob' });
        
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setProfilePic(url);
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchProfilePic(); // Call the fetchProfilePicture function

  }, [currentUser._id, profilepic]);

  useEffect(() => {
    const fetchBackgroundPic = async () => {
      try {
        // Fetch the user's profile picture from your server using the username
        const response = await axiosInstance.get(`/users/getuserbackgroundpicturebyid/${currentUser._id}`, { responseType: 'blob' });
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setBackgroundPic(url);
        }
      } catch (error) {
        console.error('Error fetching background picture:', error);
      }
    };
  
    fetchBackgroundPic(); // Call the fetchProfilePicture function
  
  }, [currentUser._id]);

  // useEffect(() => {
  //   const fetchProfilePic = async () => {
  //     try {
  //       // Fetch the user's profile picture from your server
  //       const response = await axiosInstance.get(`/users/getuserbackgroundpicture/${currentUser._id}`, { responseType: 'blob' });
        
  //       if (response.status === 200) {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         setBackgroundPic(url);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching profile picture:', error);
  //     }
  //   };

  //   fetchProfilePic(); // Call the fetchProfilePicture function

  // }, [currentUser._id]);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axiosInstance.get(`/users/profileTitle?userId=${currentUser._id}`);
        setProfileTitle(res.data.profileTitle);
        setBackgroundType(res.data.backgroundType);
        setUserPlan(res.data.userplan);
        const userLinksResponse = await axios.get(`https://linkedxio-e6f82560b206.herokuapp.com/api/userlinks?userId=${currentUser._id}`);
        setUserLinks(userLinksResponse.data);
        setBackgroundColor(res.data.backgroundColor);
        setverifiedStatus(res.data.bluecheck);
        setpremiumStatus(res.data.goldcheck);
        setLogo(res.data.logo)

      } catch (error) {
        console.error('Error fetching profileTitle:', error);
      }
    };

    // Poll the server every X milliseconds
    const pollingInterval = 1000; // Adjust this to your desired frequency
    const pollTimer = setInterval(fetchUserData, pollingInterval);

    // Clean up the interval on component unmount
    return () => clearInterval(pollTimer);
  }, [currentUser._id, currentUser.logo]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axiosInstance.get(`/users/profileBio?userId=${currentUser._id}`);
        setProfileBio(res.data.profileBio);
      } catch (error) {
        console.error('Error fetching profileTitle:', error);
      }
    };

    // Poll the server every X milliseconds
    const pollingInterval = 1000; // Adjust this to your desired frequency
    const pollTimer = setInterval(fetchUserData, pollingInterval);

    // Clean up the interval on component unmount
    return () => clearInterval(pollTimer);
  }, [currentUser._id, profileBio]);

  const backgroundStyle = {
    backgroundImage: `url(${backgroundpic})`, // Set the background image
    backgroundColor: backgroundColor,
    backgroundSize: 'cover',
    backgroundPosition: 'center', // Center the background image
    backgroundRepeat: 'no-repeat', // Prevent image from repeating if it's smaller than the container
  
  };

  useEffect(() => {
    console.log('Profile Picture URL:', profilepic);
  }, [profilepic]);
 
  return (
    <div className="profile-container2">
      {backgroundType === 'FlatColour' && (
        <div className="profile-content" style={{ backgroundColor: backgroundColor }}>
          <section className="animated-background">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
          </section>

          <a id="profilePicture">
            {profilepic ? (
              <img src={profilepic} alt="Profile" />
            ) : (
              <p className="profilenulltext">@</p>
            )}
          </a>

          <div className="overlay" id="popup">
            <div className="popup">
              <div className="popup-photo">
                <a href="https://avatars0.githubusercontent.com/u/43749971" target="_blank" rel="noopener noreferrer">
                  <img src="https://pbs.twimg.com/profile_images/1700343203616940032/Y0KMbn_z_400x400.jpg" alt="ProfilePicture" />
                </a>
              </div>
              <div className="popup-quote">We locked in.</div>
              <button className="popup-close" onClick={() => window.history.back()}>&times;</button>
            </div>
          </div>

          <div id="userName" className="userName">
            {profileTitle}
            {verifiedStatus && (
    <img src="https://linkedx.io/bluecheck.svg" alt="Blue Check Mark" className="blue-check-mark" />
    )}
    {premiumStatus && (
    <img src="https://linkedx.io/goldcheck.png" alt="Gold Check Mark" className="gold-check-mark" />
    )}
          </div>
          <div id="userName" className="bioName">
            {profileBio}
          </div>

          <div id="links">
            {userLinks.map((link, index) => (
              <a
                key={index}
                className="link"
                href={link.url.startsWith('http://') || link.url.startsWith('https://') ? link.url : `http://${link.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`fab ${link.icon}`}>&nbsp;</i>
                {link.title}
              </a>
            ))}
          </div>

          {!logo && <div id="hashtag">LinkedX.io</div>}
        </div>
      )}

      {backgroundType === 'Image' && backgroundpic && (
        <div className="profile-content"  style={backgroundStyle}>
          <section className="animated-background">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
          </section>
          <a id="profilePicture">
            {profilepic ? (
              <img src={profilepic} alt="Profile" />
            ) : (
              <p className="profilenulltext">@</p>
            )}
          </a>

          <div className="overlay" id="popup">
            <div className="popup">
              <div className="popup-photo">
                <a href="https://avatars0.githubusercontent.com/u/43749971" target="_blank" rel="noopener noreferrer">
                  <img src="https://pbs.twimg.com/profile_images/1700343203616940032/Y0KMbn_z_400x400.jpg" alt="ProfilePicture" />
                </a>
              </div>
              <div className="popup-quote">We locked in.</div>
              <button className="popup-close" onClick={() => window.history.back()}>&times;</button>
            </div>
          </div>

          <div id="userName" className="userName">
            {profileTitle}
            {verifiedStatus && (
    <img src="https://linkedx.io/bluecheck.svg" alt="Blue Check Mark" className="blue-check-mark" />
    )}
    {premiumStatus && (
    <img src="https://linkedx.io/goldcheck.png" alt="Gold Check Mark" className="gold-check-mark" />
    )}
          </div>
          <div id="userName" className="bioName">
            {profileBio}
          </div>

          <div id="links">
            {userLinks.map((link, index) => (
              <a
                key={index}
                className="link"
                href={link.url.startsWith('http://') || link.url.startsWith('https://') ? link.url : `http://${link.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`fab ${link.icon}`}>&nbsp;</i>
                {link.title}
              </a>
            ))}
          </div>

          {!logo && <div id="hashtag">LinkedX.io</div>}
        </div>
      )}
    </div>
  );
}
