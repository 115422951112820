import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer/footer';
import { logoutCall } from '../apiCalls';
import { AuthContext } from '../context/AuthContext';
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './press.css'; // Add your CSS file for styling

export default function Press() {
    const { user: currentUser } = useContext(AuthContext);
    const isLoggedIn = !!currentUser;
    const history = useNavigate();
    const [username, setUsername] = useState('');
    const { dispatch } = useContext(AuthContext);
  
    const handleLogOutClick = () => {
        logoutCall(dispatch);
      };
      const handleClick = () => {
        if (username) {
          history(`/signup?username=${username}`);
        } else {
          // Handle the case where the username input is empty
        }
      };

      const [isMobile, setIsMobile] = useState(false);

      const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
      };
      useEffect(() => {
        updateIsMobile(); // Call it initially
        window.addEventListener('resize', updateIsMobile);
        return () => {
          window.removeEventListener('resize', updateIsMobile);
        };
      }, []);
    
  return (
    <div className="about">
      {/* Include your top bar component */}
      <nav className={`sticky-navbar ${isMobile ? 'mobile-nav' : ''}`} style={{ zIndex: 100}}>
        <div className="topbar-items">
        <div className={`logo ${isMobile ? 'mobile-logo' : ''}${isMobile ? 'mobile-logo' : 'desktop-logo'}`}><Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
 <img
                className={`navlogo${isMobile ? 'mobile' : 'desktop'}`}
                src={isMobile ? navlogomobile : navlogodesktop}
                alt=""
              />  </Link></div>
  {/* <Link to="/pricing">
            <button className={`topbar-item`}>
              Team
            </button>
          </Link> */}
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Pricing
            </button>
          </Link>
          {/* <Link to="/pricing">
            <button className={`topbar-item`}>
            Discover
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
            Pricing
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Learn
            </button>
          </Link> */}
          </div>
        <div className="login-signup">
          {isLoggedIn ? (
            <button onClick={handleLogOutClick} className={`login`}>
              Log out
            </button>
          ) : (
            <Link to="/login">
              <button className={`login ${isMobile ? 'mobile-button' : ''}`}>
                Login
              </button>
            </Link>
          )}
          {isLoggedIn ? (
            <Link to="/admin">
              <button className={`signup`}>
                Admin
              </button>
            </Link>
          ) : (
            <div className="signup-input">
              <Link to="/signup">
                <button onClick={handleClick} className={`signup ${isMobile ? 'mobile-button' : ''}`}>
                  <span style={{ whiteSpace: 'nowrap' }}>{isMobile ? 'Sign Up' : 'Sign Up Free'}</span>
                </button>
              </Link>
            </div>
          )}
         
        </div>
      </nav>
      <div className="about-container">
        <div className="about-content">
          <h1 className='hero1'>Press</h1>
          <p className='hero3'>
          For media inquiries and collaboration opportunities, reach out to our press team at <a className="hrefmail" href="mailto:press@linkedx.io">hello@linkedx.io</a>. At LinkedX, we thrive on partnering with global media to disseminate our unparalleled wealth of data about the creator economy worldwide. Whether you're interested in featuring compelling stories about the evolving digital landscape or shining a spotlight on the inspiring users and happenings at LinkedX, we welcome the opportunity to connect. If you're not a member of the media but still need to get in touch, drop us a line at <a className="hrefmail" href="mailto:hello@linkedx.io">hello@linkedx.io</a>, and let's start a conversation.
</p>
        </div>
      </div>

      {/* Include your footer component */}
      <Footer />
    </div>
  );
}
