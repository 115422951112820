import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import Adminpage from './pages/admin/Admin';
import Appearancepage from './pages/appearance/Appearance';
import Login from './pages/Login/Login';
import LoginForgot from './pages/LoginForgot/LoginForgot';
import SignUp from './pages/signup/Signup';
import Signupstarter from './pages/signup/Signupstarter'
import Signuppro from './pages/signup/Signuppro';
import Signuppremium from './pages/signup/Signuppremium';
import Verifyemail from './pages/verifyemailsent/Verifyemailsent';
import Emailverified from './pages/emailverified/Emailverified';
import { AuthContext } from './context/AuthContext';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import ForgotPassword from './components/forgotpassword/Forgotpassword';
import Plan from './pages/Myplan/Plan';
import Layout from './components/layout/layout';
import { ProSidebarProvider } from 'react-pro-sidebar';
import About from './pages/about/about';
import Press from './press/press';
import CookieNotice from './pages/cookienotice/cookienotice';
import Terms from './pages/termsandconditions/termsandconditions';
function App() {
  const { user } = useContext(AuthContext);
  const PlanHOC = Layout(Plan);

  return (
    <ProSidebarProvider>

    <Router>
      <Routes>
      <Route path="/home" element={<Home />}/>
      <Route path="/terms/" element={<Terms />}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/about/cookie-notice/" element={<CookieNotice/>}/>
      <Route path="/press" element={<Press />}/>
      <Route path="/pricing" element={<Plan />}/>
          <Route
            path="/user/plan"
            element={user ? <PlanHOC /> : <Navigate to="/login" />}
          />
        <Route element={<Home/>} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/:username" element={ <Profile />} />
        <Route path="/admin" element={user ? <Adminpage /> : <Navigate to="/login" />} />
        <Route path="/admin/appearance" element={user ? <Appearancepage /> : <Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/starter" element={<Signupstarter />} />
        <Route path="/signup/pro" element={<Signuppro />} />
        <Route path="/signup/premium" element={<Signuppremium />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup/user/:username" element={<SignUp/>} />
        <Route path="/emailsent/success" element={<Verifyemail />} />
        <Route path="/users/:id/verify/:token" element={<Emailverified />} />

      </Routes>
    </Router>
    </ProSidebarProvider>
  );
}

export default App;
