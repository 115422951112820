import React from 'react';
import { Link } from 'react-router-dom';
import './verifyemailsent.css';

export default function Verifyemail() {
  return (
    <div className="centered-container">
      <div className="success-container">
        <h1 className="success-heading">Success!</h1>
        <p className="success-message">
          Your account verification email has been sent to your email. Please open this email to verify your account. If you don't see this email in your inbox within 15 minutes, look for it in your junk mail folder. If you find it there, please mark the email as "Not Junk".
        </p>
        <Link to="/login" className="login-link" style={{ textDecoration: 'none' }}>Login</Link>
      </div>
    </div>
  );
};
