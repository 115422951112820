import React from "react";
import TwitterIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import './footer.css';


const Footer=()=>{
    return (
<div className="footer">
<div className="sb__footer section__padding">
    <div className="sb__footer-links">
        {/* <div className="sb__footer-links-div">
            <h4>Contact</h4>
            <a className="belowa" href="/Follow">
                <p>hello@linkedx.com</p>
            </a>
        </div> */}
        <div className="sb__footer-links-div">
            <h4>Company</h4>
            <a className="belowa" href="/about">
                <p>About</p>
            </a>
            <a className="belowa" href="/press">
                <p>Press</p>
            </a>
            <a className="belowa" href="mailto:hello@linkedx.io">
                <p>Contact</p>
            </a>
        </div>

        <div className="sb__footer-links-div">
            <h4>Follow Us</h4>
            <div className="socialmedia">
            <a href="https://x.com/LinkedX_" style={{ margin: '0 3px' , color: 'white'}}>
            <TwitterIcon />
        </a>
        <a href="https://www.instagram.com/linkedx.io/" style={{ margin: '0 3px' ,color: 'white'}}>
            <InstagramIcon />
        </a>
        <a href="https://facebook.com/LinkedXFB" style={{ margin: '0 3px' ,color: 'white' }}>
            <FacebookIcon />
        </a>
            </div>

        </div>
    </div>
    <hr></hr>
    <div className="sb__footer-below">
        <div className="sb__footer-copyright">
            <p>
                @{new Date().getFullYear} LinkedX. All rights reserved.
            </p>
        </div>
        <div className="sb__footer-below-links">
            <a className="belowa" href="/terms"> <div><p>Terms & Conditions</p></div></a>
            <a  className="belowa" href="/about/cookie-notice/"> <div><p>Cookie Policy</p></div></a>
        </div>
    </div>
</div>

</div>
    )
}

export default Footer;