import React, { useContext, useState, useEffect } from 'react';
import { RecoveryContext } from '../forgotpassword/Forgotpassword';
import axios from 'axios';
import { axiosInstance } from '../../config';

const containerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f0f4f8',
};

const mainContainerStyle = {
  background: 'white',
  padding: '20px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
};

const titleStyle = {
  fontWeight: '600',
  fontSize: '24px',
  marginBottom: '16px',
};

const codeContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '300px',
};

const inputContainerStyle = {
  flex: '1',
  marginLeft: '8px',
};

const inputStyle = {
  width: '69%',
  height: '48px',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid #d1d5db',
  fontSize: '14px',
  backgroundColor: 'white',
  outline: 'none',
};

const buttonStyle = {
  width: '100%',
  height: '48px',
  marginTop: '16px',
  borderRadius: '8px',
  border: 'none',
  fontSize: '16px',
  color: 'white',
  backgroundColor: '#2563eb',
  cursor: 'pointer',
};

const ResendOTP = () => {
  const { email, otp, setPage , setRecoveryInfo , recoveryInfo} = useContext(RecoveryContext);
  const [timerCount, setTimer] = useState(60);
  const [OTPinput, setOTPinput] = useState(['', '', '', '']);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000); // each count lasts for a second

    // cleanup the interval on complete
    return () => clearInterval(interval);
  }, [disable]);

  useEffect(() => {
    // Use the updated email value here
    console.log('Recovery information updated:', recoveryInfo);
  }, [email, recoveryInfo]); // Triggered whenever email changes


  const handleResendOTP = () => {
    if (disable) return;
    axiosInstance
      .post('/sendrecoveryemail', {
        OTP: otp,
        recipient_email: email,
        email: recoveryInfo.email,
      })
      .then(() => setDisable(true))
      .then(() => alert('A new OTP has successfully been sent to your email.'))
      .then(() => setTimer(60))
      .catch(console.log);
  };

  const verifyOTP = (e) => {
    e.preventDefault(); // Prevent the default form submission
    const enteredOTP = parseInt(OTPinput.join(''));
    if (!isNaN(enteredOTP) && enteredOTP === otp) {
        setPage('reset');
    } else {
      alert('The code you have entered is not correct, try again or re-send the link');
    }
  };

  return (
    <div style={containerStyle}>
      <div style={mainContainerStyle}>
        <div>
          <div style={titleStyle}>Email Verification</div>
          <div style={{ marginBottom: '16px', fontSize: '14px' }}>
            We have sent a code to your email {email}
          </div>

          <form>
            <div style={codeContainerStyle}>
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} style={inputContainerStyle}>
                  <input
                    maxLength="1"
                    style={inputStyle}
                    type="text"
                    name=""
                    id=""
                    onChange={(e) => {
                      const updatedOTPInput = [...OTPinput];
                      updatedOTPInput[index] = e.target.value;
                      setOTPinput(updatedOTPInput);
                    }}
                  ></input>
                </div>
              ))}
            </div>

            <button onClick={(e) => verifyOTP(e)} style={buttonStyle}>
              Verify Account
            </button>

            <div style={{ marginTop: '16px', fontSize: '14px', color: '#718096' }}>
              Didn't receive code?{' '}
              <span
                onClick={handleResendOTP}
                style={{
                  color: disable ? '#a0aec0' : '#2563eb',
                  cursor: disable ? 'not-allowed' : 'pointer',
                  textDecorationLine: disable ? 'none' : 'underline',
                }}
              >
                {disable ? `Resend code in ${timerCount}s` : 'Resend Code'}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResendOTP;
