import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './homeprofilepreview.css'; // Make sure to import the CSS file

const AnimatedPersonaProfile = ({ isVisible, onClick , personaData }) => {
  const [index, setIndex] = useState(0);
  const [rotate, setRotate] = useState(false);

  const rotateAnimation = useSpring({
    // transform: `rotateY(${index % 2 === 0 ? 720 : 0}deg)`,

  });

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setRotate(true); // Trigger rotation
    }, 3000);
  
    return () => clearInterval(rotationInterval);
  }, []);
  
  useEffect(() => {
    if (rotate) {
      setIndex((prevIndex) => (prevIndex + 1) % personaData.length);
      setRotate(false); // Reset rotation trigger
    }
  }, [rotate, personaData]);

  return (
      <animated.div
      className={`animated-persona-profile ${isVisible ? 'visible' : 'hidden'}`}
      style={{ ...rotateAnimation, cursor: 'pointer'}}
      onClick={onClick}
      
      >
       {personaData && personaData.length > 0 && personaData[index] && (
        <div>
          {/* Uncomment if you want to display name */}
          {/* <h2>{personaData[index].name}</h2> */}

          <img
            className='previewstyles'
            src={personaData[index].image}
            style={{ width: '275px', height: '504px', marginTop: '10px', zIndex: 0 }}
            alt={personaData[index].name}
          />
        </div>
      )}
    </animated.div>
  );
};

export default AnimatedPersonaProfile;
