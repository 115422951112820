import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./emailverified.css";
import Fragment from 'render-fragment';
import { useNavigate } from "react-router-dom";

const Emailverified = () => {
	const [validUrl, setValidUrl] = useState(true);
	const param = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const verifyEmailUrl = async () => {
			try {
				const url = `https://linkedxio-e6f82560b206.herokuapp.com/api/users/${param.id}/verify/${param.token}`;
				const { data } = await axios.get(url);
				console.log(data);
				setValidUrl(true);
				
			} catch (error) {
				console.log(error);
				setValidUrl(false);
			}
		};
		verifyEmailUrl();
	}, [param]);
	
	useEffect(() => {
		if (validUrl) {
		  setTimeout(() => {
			alert("Email successfully verified");
			navigate("/login");
		}, 3000);
		}
	  }, [validUrl]);
	return (
		
        <Fragment>
        {validUrl ? (
            <div className="successpage">
                <h1>Email verified successfully</h1>
                {
					

					setTimeout(() => {
						navigate("/login");
					}, 3000)
				}
            </div>
        ) : (
            <h1>404 Not Found</h1>
        )}
    </Fragment>
		
			
	);
};

export default Emailverified;