import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/footer';
import { logoutCall } from '../../apiCalls';
import { AuthContext } from '../../context/AuthContext';
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './cookienotice.css'; // Add your CSS file for styling

export default function CookieNotice() {
    const { user: currentUser } = useContext(AuthContext);
    const isLoggedIn = !!currentUser;
    const history = useNavigate();
    const [username, setUsername] = useState('');
    const { dispatch } = useContext(AuthContext);
  
    const handleLogOutClick = () => {
        logoutCall(dispatch);
      };
      const handleClick = () => {
        if (username) {
          history(`/signup?username=${username}`);
        } else {
          // Handle the case where the username input is empty
        }
      };

      const [isMobile, setIsMobile] = useState(false);

      const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
      };
      useEffect(() => {
        updateIsMobile(); // Call it initially
        window.addEventListener('resize', updateIsMobile);
        return () => {
          window.removeEventListener('resize', updateIsMobile);
        };
      }, []);
    
  return (
    <div className="about">
      {/* Include your top bar component */}
      <nav className={`sticky-navbar ${isMobile ? 'mobile-nav' : ''}`} style={{ zIndex: 100}}>
        <div className="topbar-items">
        <div className={`logo ${isMobile ? 'mobile-logo' : ''}${isMobile ? 'mobile-logo' : 'desktop-logo'}`}><Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
 <img
                className={`navlogo${isMobile ? 'mobile' : 'desktop'}`}
                src={isMobile ? navlogomobile : navlogodesktop}
                alt=""
              />  </Link></div>
  {/* <Link to="/pricing">
            <button className={`topbar-item`}>
              Team
            </button>
          </Link> */}
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Pricing
            </button>
          </Link>
          {/* <Link to="/pricing">
            <button className={`topbar-item`}>
            Discover
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
            Pricing
            </button>
          </Link>
          <Link to="/pricing">
            <button className={`topbar-item`}>
              Learn
            </button>
          </Link> */}
          </div>
        <div className="login-signup">
          {isLoggedIn ? (
            <button onClick={handleLogOutClick} className={`login`}>
              Log out
            </button>
          ) : (
            <Link to="/login">
              <button className={`login ${isMobile ? 'mobile-button' : ''}`}>
                Login
              </button>
            </Link>
          )}
          {isLoggedIn ? (
            <Link to="/admin">
              <button className={`signup`}>
                Admin
              </button>
            </Link>
          ) : (
            <div className="signup-input">
              <Link to="/signup">
                <button onClick={handleClick} className={`signup ${isMobile ? 'mobile-button' : ''}`}>
                  <span style={{ whiteSpace: 'nowrap' }}>{isMobile ? 'Sign Up' : 'Sign Up Free'}</span>
                </button>
              </Link>
            </div>
          )}
         
        </div>
      </nav>
      <div className="about-container">
        <div className="about-content">
          <h1 className='hero1'>Cookie Notice</h1>
           <p className='hero3'>Last updated: Sunday Jan 7, 2023</p>

        <p className='hero3'>
          This Cookie Notice explains how LinkedX LLC and its group companies (“LinkedX”, “we”, “us” and “ours”) use cookies and similar tracking technologies, including SDKs (together, “cookies”) on or through our website at https://linkedx.io/ (“Platform”), or when you visit a LinkedX member’s profile (“User Profile”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
        </p>

        <h2 className='hero1'>What are cookies?</h2>
        <p className='hero3'>
          Cookies are small data files that are placed on your computer or mobile device. Website owners can use cookies for a variety of reasons that can include enabling the website to work (or work more efficiently), providing personalised content and advertising, and creating analytics. Cookies set by the website owner (in this case, LinkedX) are called “first party cookies”. Only LinkedX can access the first-party cookies it sets.
        </p>

        <p className='hero3'>
          Cookies set by parties other than LinkedX are called “third-party cookies”. Third-party cookies enable third-party features or functionality (e.g. like advertising, interactive content, and social sharing) to be provided on or through the website. The parties that set these third-party cookies on websites can recognize your device both when it visits the website in question and also when it visits other websites that have partnered with them.
        </p>

        <h2 className='hero1'>Why do we use cookies?</h2>
        <p className='hero3'>
          We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons that are strictly necessary to provide LinkedX services, and we refer to these as “essential” cookies. Other cookies also enable us to carry out analytics, to personalize and enhance your experience and/or to show you more relevant content and advertising.
        </p>

        <p className='hero3'>
          In addition to the cookies used by LinkedX for these LinkedX purposes, LinkedX Users can adjust their settings to set first and third-party cookies on their LinkedX User Profile for their own independent purposes of a similar nature.
        </p>

        <h2 className='hero1'>Where can you get further information?</h2>
        <p className='hero3'>If you have any questions about our use of cookies or other technologies, please email us at <a className="hrefmail" href="mailto:support@linkedx.io">support@linkedx.io</a>.</p>
        </div>
      </div>

      <Footer />
    </div>
  );
}
