import React from 'react';
import { Link } from 'react-router-dom';
import './recovered.css'

const containerStyle = {
  height: '100%',
  margin: '0 auto', // Center the content horizontally
  alignItems: 'center', // Center the content vertically
  justifyContent: 'center', // Center the content horizontally

  margin: '0 auto', // Center the content horizontally

};

const sectionStyle = {
  padding: '0 6px',
  height: '100%',
  color: '#4b5563',
  display: 'flex',
  alignItems: 'center', // Center the content vertically
  justifyContent: 'center', // Center the content horizontally

};

const imageContainerStyle = {
  flex: '0 1 auto',
  marginBottom: '24px',
  display: 'flex',
  alignItems: 'center', // Center the content vertically
  justifyContent: 'center', // Center the content horizontally

};

const imageStyle = {
  width: '50%',
};

const formContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the content vertically
    justifyContent: 'center', // Center the content horizontally
  

};

const titleStyle = {
  fontSize: '1.5rem',
  fontWeight: '700',
  marginBottom: '0',
  marginRight: '1rem',
  color: 'white',
};

const welcomeTextStyle = {
    margin: '0',
    display: 'flex',
    color: '',
    alignItems: 'center', // Center the content vertically
    justifyContent: 'center', // Center the content horizontally
    textDecoration: 'underline', // Add underline to the text
  
};

const Recovered = () => {
  return (
    <div style={containerStyle}>
      <section style={sectionStyle}>
        <div className="loginformcontainer">
          <div style={imageContainerStyle}>
            <img className='loginformphoto'
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              alt="Sample image"
              style={imageStyle}
            />
          </div>
          <div style={formContainerStyle}>
            <form>
              <div className="flex flex-row items-center justify-center lg:justify-start">
                <h1 style={titleStyle}>Password successfully set</h1>
              </div>

              <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <h2 style={welcomeTextStyle}>Login</h2>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Recovered;
