import axios from "axios";
import { axiosInstance } from "./config";



export const loginCall = async (userCredential, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  try {
    const res = await axiosInstance.post("/auth/login", userCredential);
    dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
  } catch (err) {
    if (err.response.status === 400) {
      dispatch({ type: "EMAIL_NOT_VERIFIED", payload: err.response.data.message });
    } else {
      dispatch({ type: "LOGIN_FAILURE", payload: err });
    }
    throw err;
  }
};

export const updateCoins = async (userCredential) => {
  try {
   
    const res = await axiosInstance.put("/coins/", userCredential);
  } catch (err) {
   
  }
};

// export const premiumExpiration = async (userCredential) => {
//   try {
   
//     const res = await axiosInstance.put("/premium/", userCredential);
//   } catch (err) {
   
//   }
// };

export const premiumExpireSet = async (userCredential) => {
  try {
   
    const res = await axiosInstance.put("/premium/", userCredential);
  } catch (err) {
   
  }
};


export const logoutCall = async (dispatch) => {
  dispatch({ type: "LOGOUT" });
};

