import React, { useContext, useRef, useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { loginCall } from "../../apiCalls";
import axios from 'axios';
import { axiosInstance } from "../../config";
import { RecoveryContext } from "../../components/forgotpassword/Forgotpassword";
import './loginforgot.css'; // Import your CSS file

export default function LoginForgot() {
  const [errors, setError] = useState('');
  const { setEmail, setPage, email, setOTP, isFetching, setIsFetching ,setRecoveryInfo , recoveryInfo} = useContext(RecoveryContext);
  const emailRef = useRef(); // Create a ref for the email input

  function navigateToOtp() {
    const enteredEmail = emailRef.current.value.toLowerCase(); // Access the email input value using the ref
    if (enteredEmail) {
      setIsFetching(true);
      const OTP = Math.floor(Math.random() * 9000 + 1000);
      console.log(OTP);
      setOTP(OTP);

      axiosInstance
        .post("/sendrecoveryemail", {
          OTP,
          recipient_email: enteredEmail,
        })
        .then(() => {
          setPage("otp");
          setRecoveryInfo(enteredEmail);
          setIsFetching(false); // Set isFetching to false when the operation is successful
        })
        .catch(error => {
          console.error(error);
          setIsFetching(false); // Set isFetching to false when there's an error
        });
      return;
    }
    return alert("Please enter your email");
  }


  const handleChange = e => {
    setEmail(e.target.value);  
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <Link to="/" className="logo link-style">
          <h1>Reset your password</h1>
        </Link>
        <p>Enter email of account you want to reset</p>
      </div>

      <div className="login-form" >
        <div className="form-group">
          <label htmlFor="emailOrUsername">Email</label>
          <input className="input2" type="text" name="email"ref={emailRef} placeholder="Email" />
          <span className="error-message">Please enter an email</span>
        </div>
        <button className="login-button" type="submit" onClick={() => navigateToOtp()} disabled={isFetching}>
        {isFetching ? <CircularProgress style={{ color: 'white', size: '7px' }}/> : "Reset password"}
        </button>
        {errors && <span className="error-message">{errors}</span>}
        <div className="login-link1" >
         <Link to="/login" style={{ textDecoration: 'none' }}>Back to login</Link>
        </div>
      </div>
    </div>
  );
}