import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function createData(name, col1, col2, col3) {
    return { name, col1, col2, col3 };
}

const rows = [
    createData('Unlimited links', <DoneIcon sx={{ color: "forestgreen" }} />, <DoneIcon sx={{ color: "forestgreen" }} />, <DoneIcon sx={{ color: "forestgreen" }} />),
    createData('Verified Checkmark', <CloseIcon sx={{ color: "red" }} />, <DoneIcon sx={{ color: "forestgreen" }} />, <DoneIcon sx={{ color: "forestgreen" }} />),
    createData('Advanced customization options', <CloseIcon sx={{ color: "red" }} />, <DoneIcon sx={{ color: "forestgreen" }} />, <DoneIcon sx={{ color: "forestgreen" }} />),
    createData('Gold Checkmark', <CloseIcon sx={{ color: "red" }} />, <CloseIcon sx={{ color: "red" }} />, <DoneIcon sx={{ color: "forestgreen" }} />),
];

const PriceComparison = () => {
    return (
        <>
            <Container sx={{ pt: 8 }} >

            <TableContainer component={Paper} sx={{ borderRadius: '25px',overflowX: 'auto' }}>
                    <Table size="small" aria-label="a dense table" >
                        <TableHead sx={{ bgcolor: '#1976d2' }}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="center" sx={{ fontSize: '22px', color: 'whitesmoke', p: 2,whiteSpace: 'nowrap' }}>Starter</TableCell>
                                <TableCell align="center" sx={{ fontSize: '22px', color: 'whitesmoke', p: 2,whiteSpace: 'nowrap' }}>Pro</TableCell>
                                <TableCell align="center" sx={{ fontSize: '22px', color: 'whitesmoke', p: 2, whiteSpace: 'nowrap' }}>Gold</TableCell>
                                {/* <TableCell align="center">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, p: 3 }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.col1}</TableCell>
                                    <TableCell align="center">{row.col2}</TableCell>
                                    <TableCell align="center">{row.col3}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

        </>
    );
}
export default PriceComparison;
