import React, { useContext, useRef, useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { loginCall } from "../../apiCalls";
import axios from 'axios';
import { axiosInstance } from "../../config";
import './login.css'; // Import your CSS file

export default function Login() {
  const email = useRef();
  const password = useRef();
  const { user, isFetching, dispatch, error } = useContext(AuthContext);
  const [errors, setError] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);  


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClick = (e) => {
    e.preventDefault();
    loginCall({ email: email.current.value.toLowerCase(), password: password.current.value }, dispatch)
      .then(() => {
        navigate("/admin");
      })
      .catch((err) => {
        if (err.response.data.message === "Email not verified. Check your email.") {
          setError(err.response.data.message);
        } else {
          setError('Incorrect email or password');
        }
      });
  };

  const handleTwitterSignIn = () => {
    window.location.href = "http://spiritrealm.live/auth/twitter";
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <Link to="/" className="logo link-style">
          <h1>LinkedX.io</h1>
        </Link>
        <p>Welcome back</p>
        <p>Log in to your LinkedX.io</p>
      </div>

      <form className="login-form" onSubmit={handleClick}>
        <div className="form-group">
          <label htmlFor="emailOrUsername">Email</label>
          <input className="input2" type="text" id="emailOrUsername" name="emailOrUsername" placeholder="Email" ref={email} />
          <span className="error-message">Please enter an email</span>
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            className="input2"
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            placeholder="Password"
            ref={password}
          />
          <span className="show-password" onClick={togglePasswordVisibility}>
            {showPassword ? 'Hide' : 'Show'}
          </span>
          <span className="forgot-links">
            <a href="/forgot-password" >Forgot password?</a> 
            {/* • <a href="/forgot-username">Forgot username?</a> */}
          </span>
        </div>

        <button className="login-button" type="submit" disabled={isFetching}>
          {isFetching ? <CircularProgress /> : "Log In"}
        </button>
        {errors && <span className="loginError">{errors}</span>}
        <div className="signup-link">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </div>
      </form>
    </div>
  );
}
