import React, { useEffect } from 'react'
import { Sidebar, Menu, MenuItem, menuClasses } from 'react-pro-sidebar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';


const SidebarAdm = () => {
    // const { userInfo } = useSelector(state => state.signIn);
    


    //log out



    return (
        <>
            <Sidebar backgroundColor="white" style={{ borderRightStyle: "none" }} >

                <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100vh" }}>
                    <Box>
                        <Menu
                            menuItemStyles={{

                                button: {
                                    [`&.active`]: {
                                        backgroundColor: 'red',
                                        color: 'green',
                                    },
                                    [`&.${menuClasses.button}`]: {
                                        //color: "#fafafa",
                                        color: "#000000",
                                    },
                                    [`&.${menuClasses.disabled}`]: {
                                        color: "green",
                                    },
                                    '&:hover': {
                                        backgroundColor: "rgba(23,105,170, 1)",
                                        color: "#fafafa",
                                    },
                                },

                                icon: {
                                    [`&.${menuClasses.icon}`]: {
                                        color: 'blue',
                                    }
                                },
                            }}
                        >

                            <>
                                <MenuItem component={<Link to="/user/dashboard" />} icon={<DashboardIcon />}> Dashboard </MenuItem>
                                <MenuItem component={<Link to="/user/plan" />} icon={<PriceCheckIcon />}> Plan </MenuItem>
                            </>


                        </Menu>
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <Menu
                            menuItemStyles={{

                                button: {
                                    [`&.${menuClasses.button}`]: {
                                        color: "#000000",
                                    },

                                    '&:hover': {
                                        backgroundColor: "rgba(23,105,170, 1)",
                                        color: "#fafafa",
                                    },
                                },

                                icon: {
                                    [`&.${menuClasses.icon}`]: {
                                        // color: "blue",
                                        color: 'blue',
                                    }
                                },
                            }}
                        >
                            <MenuItem icon={<LoginIcon />}>   Log out </MenuItem>
                        </Menu>
                    </Box>
                </Box>
            </Sidebar>
        </>
    )
}

export default SidebarAdm