import React, { useEffect, useState, useContext, useRef , useLayoutEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faBolt, faDroplet, faImage,faLock, faStar , faTimes} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './appearance.css';
import TopBarMobile from '../../components/topbar/TopbarMobile';
import TopBar from '../../components/topbar/Topbar';
import { AuthContext } from '../../context/AuthContext';
import ProfilePreview from '../profilepreview/ProfilePreview';
import { SketchPicker } from 'react-color';
import { axiosInstance } from "../../config";
import { Link } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import ProPromo from './threecards.png';



export default function Appearancepage() {
  const [file, setFile] = useState()
  const [profilepic, setProfilePic] = useState()
  const [user, setUser] = useState({});
  const [userplan, setUserPlan] = useState({});
  const { user: currentUser } = useContext(AuthContext);
  const [image, setImage] = useState();
  const [backgroundFile, setbgfImage] = useState();
  const [profileTitle, setProfileTitle] = useState('');
  const [profileBio, setProfileBio] = useState('');
  const [backgroundFX, setBackgroundFX] = useState('');
  const [title, setTitle] = useState('');
  const [bio, setBio] = useState('');
  const [bioCharacterCount, setBioCharacterCount] = useState(0); // Track character count
  const fileInputRef = useRef(null);
  const backgroundImageInputRef = useRef(null);
  const [particlesActive, setParticlesActive] = useState(false);

  const [selectedSquare, setSelectedSquare] = useState(null); // Track selected square
  const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // State to store selected background color
  const [showColorPicker, setShowColorPicker] = useState(false); // State to show/hide color picker dialog
  const [color, setColor] = useState('#223344'); // Replace with the default color
  const [initialBackgroundColor, setInitialBackgroundColor] = useState('#223344'); // Replace with the default color
  const [showPreview, setShowPreview] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(true);

  const handleHideCard = () => {
    setIsCardVisible(false);
  };

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the screen width as needed
  };
  useLayoutEffect(() => {
    if (isMobile) {
      document.documentElement.style.overflowX = 'hidden';
    }

    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.documentElement.style.overflowX = 'visible';
    };
  }, [isMobile]);
  useEffect(() => {
    updateIsMobile(); // Call it initially
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);
  
  const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',  // Use 'inherit' to maintain the parent's color or specify your desired color
  };
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  const previewButton = (
    <button className="preview-button" onClick={togglePreview}>
      <span className="preview-text">Preview</span>
      {showPreview && (
        <span className="close-icon" onClick={closePreview}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      )}
    </button>
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Fetch the user's data, including the profile title
        const response = await axiosInstance.get(`/users/profileTitle?userId=${currentUser._id}`);
  
        const userData = response.data;
        setProfileTitle(userData.profileTitle);
        setProfileBio(userData.profileBio);
        setBackgroundFX(userData.backgroundEffects);
        setUser(userData);
        setUserPlan(userData.userplan);
        setTitle(userData.profileTitle || ''); // Set the title with the profileTitle, or an empty string if it's not available
        setInitialBackgroundColor(userData.backgroundColorHex || '#223344'); // Replace with the default color

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    
    fetchUser();
  }, [currentUser._id, profileTitle, profilepic, userplan]);
  
  
 useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        // Fetch the user's profile picture from your server
        const response = await axiosInstance.get(`/users/getuserprofilepicture/${currentUser._id}`, { responseType: 'blob' });
        
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setProfilePic(url);
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchProfilePic(); // Call the fetchProfilePicture function

  }, [currentUser._id]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Fetch the user's data, including the profile title
        const response = await axiosInstance.get(`/users/profileBio?userId=${currentUser._id}`);
        const userData = response.data;
        setBio(response.data.profileBio || '');
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [profileBio]);

  useEffect(() => {
    setBioCharacterCount(bio.length);
  }, [bio]);

  const ToggleLogoButton = ({ userId, initialLogoValue }) => {
    const [logo, setLogo] = useState(initialLogoValue); // Initial value, assuming initialLogoValue is a boolean
  


    useEffect(() => {
      const fetchLogoStatus = async () => {
        try {
          // Fetch the user's logo status from the server
          const response = await axiosInstance.get(`/users/logostatus?userId=${currentUser._id}`);
          const initialLogoValue = response.data.logo; // Replace with the actual field name in your response
          console.log('Fetched logo status:', initialLogoValue);

          // Update the state with the initial logo value
          setLogo(initialLogoValue);
        } catch (error) {
          console.error('Error fetching initial logo status:', error);
        }
      };
  
      fetchLogoStatus(); // Call the function to fetch the initial logo status
    }, []);


    const handleToggle = async (newLogoValue) => {

      if (userplan === "Starter" || userplan === "none") {
        // Redirect to /pricing using window.location.href
        window.location.href = '/pricing';
        return; // Exit the function to prevent further logic
      }
      try {
        // Update the user's schema property "logo" in the database
        await axiosInstance.post("/updateuserlogo", {
          userId: userId,
          logo: newLogoValue,
        });
  
        // Update the state locally
        setLogo(newLogoValue);
      } catch (error) {
        console.error('Error updating user logo:', error);
      }
    };
  
    return (
      <ToggleButtonGroup
      type="checkbox"
      value={logo ? [1] : []}
      onChange={(values) => {
        // If the checkbox is checked, set newLogoValue to true, otherwise, set it to false
        const newLogoValue = values.length > 0;
        handleToggle(newLogoValue);
      }}
    >
      <ToggleButton value={1}>LinkedX</ToggleButton>
    </ToggleButtonGroup>
    );
  };
  

  const handleUpload = (image) => {
    const userId = currentUser._id;
    const formdata = new FormData();
    formdata.append('image', image);
    formdata.append('userId', userId);

    axios.post("https://linkedxio-e6f82560b206.herokuapp.com/api/upload", formdata)
      .then(res => {
        console.log(res);
        // Assuming the response contains the URL of the uploaded image
        const imageUrl = res.data.imageUrl;

        // Update the profilepic state with the new image URL
        setProfilePic(imageUrl);
         // Refresh the page to see the updated profile picture
      window.location.reload();
      })
      .catch(err => console.log(err));
  };
  const saveBackgroundColor = (color) => {
    axiosInstance
      .post('/savebackgroundcolor', {
        userId: currentUser._id,
        backgroundColor: color,
      })
      .then((response) => {
        // Handle success
        console.log('Background color saved:', response.data);
      })
      .catch((error) => {
        // Handle error
        console.error('Error saving background color:', error);
      });
  };

  const updateBackgroundColor = async (newColor) => {
    try {
      const response = await axiosInstance.post("/savebackgroundcolor", {
        userId: currentUser._id,
        backgroundColor: newColor,
      });
  
      // Assuming the response contains the updated user information
      const updatedUser = response.data;
  
      // Update the user state with the new background color value
      setUser(updatedUser);
    } catch (error) {
      console.error('Error updating background color:', error);
    }
  };
  const handleColorChange = (newColor) => {
    // Update the color state with the selected color
    setColor(newColor.hex);
  
    // Update the user's background color in the database
    updateBackgroundColor(newColor.hex);
  };

  const handleColorPickerToggle = () => {
    setShowColorPicker(!showColorPicker); // Toggle the color picker dialog
  
    if (!showColorPicker) {
      // When opening the color picker, set the color to the initial background color
      setColor(initialBackgroundColor);
    }
  };
  const availableEffects = [
    { name: 'particles', active: false },
    // Add more effects here
  ];

  const toggleEffect = async (effectName) => {
    let updatedEffects = [];
  
    if (effectName === 'particles') {
      // Toggle the "particles" feature's status
      setParticlesActive(!particlesActive);
  
      // If "particles" is active, add it to the updatedEffects
      if (particlesActive) {
        updatedEffects.push('particles');
      }
    }
  
    try {
      // Send the updatedEffects as a string to the database
      const response = await axiosInstance.post("/updatebackgroundeffects", {
        userId: currentUser._id,
        backgroundEffects: updatedEffects.join(', '), // Convert to a comma-separated string
      });
  
      // Handle success or update the user state
      // Example: setUser(response.data);
    } catch (error) {
      console.error('Error updating background effects:', error);
    }
  };
  
  
  

  const saveProfileTitleToDatabase = async (newTitle) => {
    try {
      const response = await axiosInstance.post("/updateprofiletitle", {
        userId: currentUser._id, // Include the user's ID
        profileTitle: newTitle, // Include the new profile title
      });
  
      // Assuming the response contains the updated user information
      const updatedUser = response.data;
  
      // Update the user state with the new profileTitle value
      setUser(updatedUser);
  
      // Handle success (e.g., display a success message)
    } catch (error) {
      // Handle error (e.g., display an error message)
      console.error('Error updating profile title:', error);
      // You can set an error message state here to display to the user
    }
  };

  const saveProfileBioToDatabase = async (newBio) => {
    try {
      const response = await axiosInstance.post("/updateprofilebio", {
        userId: currentUser._id, // Include the user's ID
        profileBio: newBio, // Include the new profile title
      });
  
      // Assuming the response contains the updated user information
      const updatedUser = response.data;
  
      // Update the user state with the new profileTitle value
      setUser(updatedUser);
  
      // Handle success (e.g., display a success message)
    } catch (error) {
      // Handle error (e.g., display an error message)
      console.error('Error updating profile title:', error);
      // You can set an error message state here to display to the user
    }
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setImage(image)
    console.log(image)
    handleUpload(image);
  };

  const handleBGImageChange = (e) => {
    const backgroundFile = e.target.files[0];
    setbgfImage(backgroundFile)
    console.log(backgroundFile)
    handleBGUpload(backgroundFile);
  };

  const handleBGUpload = (backgroundFile) => {

    const userId = currentUser._id;
    const formData = new FormData();
    formData.append('backgroundImage', backgroundFile);
    formData.append('userId', userId);

    axios.post("https://linkedxio-e6f82560b206.herokuapp.com/api/upload-background", formData)
      .then(res => {
        console.log(res);
        window.location.reload();
      })
      .catch(err => console.log(err));
  };

  // const handleBackgroundImageChange = (e) => {
  //   const backgroundFile = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append('backgroundImage', backgroundFile);
  //   formData.append('userId', currentUser._id);

  //   axios
  //     .post("https://linkedxio-e6f82560b206.herokuapp.com/api/upload-background", formData, {
  //       headers: { 'Content-Type': 'multipart/form-data' },
  //     })
  //     .then((response) => {
  //       // Handle success (e.g., show a success message)
  //     })
  //     .catch((error) => {
  //       // Handle error (e.g., show an error message)
  //     });
  // };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle); // Update the state with the new title as the user types
  
    // Save the new title to the database
    saveProfileTitleToDatabase(newTitle);
  };

  const handleBioChange = (e) => {
    const newBio = e.target.value;
    setBio(newBio);

    // Limit the bio input to 80 characters
    if (newBio.length <= 80) {
      setBioCharacterCount(newBio.length); // Update character count
      saveProfileBioToDatabase(newBio); // Save the new bio to the database
    }
  };
  
  const removeProfilePicture = () => {
    if (currentUser && currentUser._id) {
      axiosInstance
        .delete(`/users/removeprofilepicture/${currentUser._id}`)
        .then((res) => {
          console.log(res);
          // Update the profilePic state with null (no image)
          setProfilePic(null);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    } else {
      console.log('currentUser is not valid.');
    }
  };

  const handleImageUpload = async () => {
    const formData = new FormData();
    formData.append('image', image);
  
    try {
      const response = await axiosInstance.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Assuming that the response contains the URL of the uploaded image
      const imageUrl = response.data.imageUrl;
  
      // Update user.profilePicture with the new image URL
      setUser((prevUser) => ({
        ...prevUser,
        profilePicture: imageUrl,
      }));
  
      // Handle success (e.g., display a success message)
    } catch (error) {
      // Handle error (e.g., display an error message)
    }
  };

  const updateProfileTitle = async () => {
    try {
      const response = await axiosInstance.post("/updateprofiletitle", {
        userId: currentUser._id, // Include the user's ID
        profileTitle: title, // Include the new profile title
      });
  
      // Assuming the response contains the updated user information
      const updatedUser = response.data;
  
      // Update the user state with the new profileTitle value
      setUser(updatedUser);
  
      // Handle success (e.g., display a success message)
    } catch (error) {
      // Handle error (e.g., display an error message)
      console.error('Error updating profile title:', error);
      // You can set an error message state here to display to the user
    }
  };

  const updateProfileBio = async () => {
    try {
      const response = await axiosInstance.post("/updateprofilebio", {
        userId: currentUser._id, // Include the user's ID
        profileBio: bio, // Include the new profile title
      });
  
      // Assuming the response contains the updated user information
      const updatedUser = response.data;
  
      // Update the user state with the new profileTitle value
      setUser(updatedUser);
  
      // Handle success (e.g., display a success message)
    } catch (error) {
      // Handle error (e.g., display an error message)
      console.error('Error updating profile title:', error);
      // You can set an error message state here to display to the user
    }
  };

  function Square({ text }) {
    const isSelected = selectedSquare === 'Flat Colour';
  
    const handleSelectSquare = async () => {
      setSelectedSquare('Flat Colour');
      try {
        // Update the user's backgroundType in the database to 'FlatColour'
        await axiosInstance.post("/updatebackgroundtype", {
          userId: currentUser._id,
          backgroundType: 'FlatColour',
        });
      } catch (error) {
        console.error('Error updating background type:', error);
      }
    };
  
    return (
      <div
        className={`square ${isSelected ? 'selected' : ''}`}
        onClick={handleSelectSquare}
      >
        <FontAwesomeIcon icon={faDroplet} className="square-image" />
        <p className="square-text">{text}</p>
      </div>
    );
  }

  function SquareImage({ text }) {
    const isSelected = selectedSquare === 'Image';
  
    const handleSelectSquare = async () => {
      setSelectedSquare(isSelected ? null : 'Image'); // Toggle selection

      if (userplan === 'none') {
        // Redirect to "/pricing"
        window.location.href = '/pricing';
        return; // Stop further execution
      }

      openFileInputBG();
      console.log('user.plan:', user.plan);

      try {
        // Update the user's backgroundType in the database to 'Image'
        await axiosInstance.post("/updatebackgroundtype", {
          userId: currentUser._id,
          backgroundType: 'Image',
        });
      } catch (error) {
        console.error('Error updating background type:', error);
      }
    };




    return (
      <div
        className={`square ${isSelected ? 'selected' : ''}`}
          onClick={handleSelectSquare}
        >

        {userplan === "none" ? (
        <Link to="/pricing" className="upgrade-bubble" style={linkStyle}>

          <div className="upgrade-bubble">
            <FontAwesomeIcon icon={faLock} className="lock-icon" />
            <FontAwesomeIcon icon={faImage} className="square-image" />
            <p className="upgrade-text">Image</p>
          </div>

          </Link>
        ) : (
          <>
            <FontAwesomeIcon icon={faImage} className="square-image" />
            <p className="square-text">{text}</p>
          </>
        )}
      </div>
    );
  }
  function SquareEffects({ text }) {
    const isSelected = selectedSquare === 'Effects';
  
    return (
      <div className={`square ${isSelected ? 'selected' : ''}`} onClick={() => setSelectedSquare('Effects')}>
        <FontAwesomeIcon icon={faStar} className="square-image" />
        <p className="square-text">{text}</p>
        {isSelected && (
          <div className="effect-buttons">
            {availableEffects.map((effect, index) => (
              <button key={index} onClick={() => toggleEffect(effect.name)}>
                {effect.active ? `Turn Off ${effect.name}` : `Turn On ${effect.name}`}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
  
  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const openFileInputBG = () => {
    backgroundImageInputRef.current.click();
  };

  const shouldShowCard = userplan === 'Starter' || userplan === 'none';


  return (
    <div>
 {isMobile ? (
      <TopBarMobile /> // Render TopBarMobile on mobile screens
    ) : (
      <TopBar /> // Render TopBar on other screens
    )}  
      <div className="container">
        <div className="left">
          <div className="content">
            <h2>Profile</h2>
            <div className="profile-container">
              <div className="profile-left">
                <div className="profile-image">
                  {profilepic ? (
                    <img src={profilepic} alt="Profile" />
                  ) : (
                    <p className='profilenulltext'>@</p>
                  )}
                </div>
              </div>
              <div className="profile-right">
                <input
                  type="file"
                  accept="image/*"
                  id="file-input"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                <button className="upload-button" onClick={openFileInput}>
                  Pick an Image
                </button>
                <button className="remove-button" onClick={removeProfilePicture}>Remove</button>
                <input
                  className='titleinput'
                  type="text"
                  placeholder="Profile Title"
                  value={title}
                  onChange={handleTitleChange}
                  onBlur={updateProfileTitle}
                />
                <textarea
                  className='bioinput'
                  placeholder="Bio"
                  value={bio}
                  onChange={handleBioChange}
                  onBlur={updateProfileBio}
                  maxLength={80}
                />
                <p className="character-count">{bioCharacterCount}/80</p>
              </div>
            </div>
          </div>
          
          {shouldShowCard && isCardVisible && (
        <div className="content">
          <div className="card">
            <div className="left-side">
              <div className="close-icon">
                <FontAwesomeIcon onClick={handleHideCard} icon={faTimes} style={{ fontSize: '1.0em', cursor: 'pointer' }} />
              </div>
              <h2 className='upgradetitle'>Get unlimited customization</h2>
              <ul className="custom-list">
                <li><FontAwesomeIcon icon={faCheck} /> Verified Checkmark</li>
                <li><FontAwesomeIcon icon={faCheck} /> Custom background images </li>
                <li><FontAwesomeIcon icon={faCheck} /> Remove the LinkedX logo</li>
              </ul>
              <Link to="/pricing" style={{ textDecoration: 'none' }}>
                <button className="upgradebutton">
                  <FontAwesomeIcon icon={faBolt} /> Upgrade
                </button>
              </Link> 
              <ul className="custom-list">
                <li>$9 /month after</li>
              </ul>
            </div>
            <div className='right-side'>
              <img className="upgradeimg" src={ProPromo} alt="Your Image" />
            </div>
          </div>
        </div>
      )}
    
          <div className="content">
            <h2>Backgrounds</h2>
            <div className="container2">
              <div className="left">
                <div className="content">
                  <div className="squares-container">
                    <Square text="Flat Colour" />
                    <SquareImage text="Image" onClick={openFileInputBG} />
                    <input
                      type="file"
                      accept="image/*"
                      id="background-image-input"
                      onChange={handleBGImageChange}
                      ref={backgroundImageInputRef}
                      style={{ display: 'none' }}
                    />
  
                    {selectedSquare === 'Flat Colour' && (
                      <div className="color-picker-container">
                        <div
                          className="background-color"
                          style={{ backgroundColor: backgroundColor }}
                        >
                          <button
                            className="color-picker-button"
                            onClick={handleColorPickerToggle}
                          >
                            Pick a Color
                          </button>
                        </div>
                        {showColorPicker && (
                          <SketchPicker
                            color={color}
                            onChange={handleColorChange}
                          />
                        )}
                      </div>
                    )}
                    {/* End of color picker logic */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
          <h2>
    {userplan === 'Starter' || userplan === 'none' ? (
      <>
<Link to="/pricing" style={linkStyle}>
        Hide the LinkedX logo <FontAwesomeIcon icon={faLock} />
      </Link>      </>
    ) : (
      'Hide the LinkedX logo'
    )}
  </h2>
            <div className="container2">
              <div className="left">
                <div className="content">
                <ToggleLogoButton userId={currentUser._id} initialLogoValue={currentUser.logo} />

                </div>
              </div>
            </div>
          </div>
        </div>
  
        {screenWidth > 768 && (
          <div className="right">
            <div className="content">
              <div className="prevcontainer">
                {<ProfilePreview/>}
              </div>
            </div>
          </div>
        )}
      </div>
  
       {isMobile && (
        <div className="mobile-sticky-bar">
          {previewButton}
          {showPreview && <ProfilePreview />}
        </div>
      )}


    </div>
    
  );
      }  