import React, { useEffect, useState, useContext } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { logoutCall } from '../../apiCalls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../context/AuthContext';
import { faBolt, faLink, faPalette, faChartBar, faCog, faSignOutAlt , faEye} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { axiosInstance } from '../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import navlogodesktop from './logofull.png';
import navlogomobile from './logosingle.png';
import './topbarmobile.css'; // You can style this component

library.add(faLink, faPalette, faChartBar, faCog, faSignOutAlt);

const TopBarMobile = () => {
  const { dispatch } = useContext(AuthContext);
  const [profilepic, setProfilePic] = useState(null);
  const { user: currentUser } = useContext(AuthContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userplan, setUserPlan] = useState({});


  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        // Fetch the user's profile picture from your server
        const response = await axiosInstance.get(`/users/getuserprofilepicture/${currentUser._id}`, { responseType: 'blob' });
        
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setProfilePic(url);
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchProfilePic(); // Call the fetchProfilePicture function

  }, [currentUser._id, profilepic]);

  const handleClick = () => {
    logoutCall(dispatch);
  };

  useEffect(() => {
    const fetchuserplan = async () => {
    try {
      const res = await axiosInstance.get(`/users/profileTitle?userId=${currentUser._id}`);
      const userData = res.data;
      setUserPlan(userData.userplan);
      console.log(userplan);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }    };

    fetchuserplan(); // Call the fetchProfilePicture function
  }, [userplan]);

  
  return (
    <div className="top-bar-mobile">
      <div className="top-bar-labels-mobile">
        <Link to="/home" style={{ textDecoration: 'none' }}>
          <div className="top-bar-label-mobile">
          <img
  className="navlogomobile"
  src={navlogomobile}
  alt=""
/>
          </div>
        </Link>

        <Link to="/admin" style={{ textDecoration: 'none' }}>
          <div className="top-bar-label-mobile">
            <FontAwesomeIcon icon="link" /> Links
          </div>
        </Link>
        <Link to="/admin/appearance" style={{ textDecoration: 'none' }}>
          <div className="top-bar-label-mobile">
            <FontAwesomeIcon icon="palette" /> Appearance
          </div>
        </Link>
        <Link to={`/${currentUser.username}`} style={{ textDecoration: 'none' }}>
          <div className="top-bar-label-mobile">
            <FontAwesomeIcon icon={faEye} /> MyPage
          </div>
        </Link>
        {/* <div className="top-bar-label">
          <FontAwesomeIcon icon="chart-bar" /> Analytics
        </div> */}
      </div>
      <div className="user-upgrade-container">
        <div className="user-profile-icon">
        {userplan === 'none' || userplan === 'Starter' ? (
            <Link to="/pricing" style={{ textDecoration: 'none' }}>
              <button className="upgrade-button2">
                <FontAwesomeIcon icon={faBolt} /> Upgrade
              </button>
            </Link>
          ) : null}

          <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)} style={{ border: 'none' }}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-basic'>
              {profilepic ? (
                <img
                  src={profilepic}
                  alt="Profile"
                  style={{ border: 'none' }} // Remove the border
                />
              ) : (
                <img className='profilenulltext-mobile' src="http://linkedx.io/nullprofile.png" alt="" />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ backgroundColor: 'black' }}>
              <Dropdown.Item onClick={handleClick} className="white-logout-text">
                <FontAwesomeIcon icon="sign-out-alt" /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default TopBarMobile;
