const AuthReducer =(state,action) =>{
    switch(action.type){
        case "LOGIN_START":
            return{
                user: null,
                isFetching: true,
                error: false,
            };
            case "LOGIN_SUCCESS":
                return {
                  ...state,
                  user: action.payload,
                  userId: action.payload.id,
                  isFetching: false,
                  error: false,
                };
            case "LOGIN_FAILURE":
                return{
                    user: null,
                    isFetching: false,
                    error: action.payload,
                };
                case "FOLLOW":
                return{
                    ...state,
                    user: {
                        ...state.user,
                        followings: [...state.user.followings, action.payload],
                    },
                };
                case "UNFOLLOW":
                return{
                    ...state,
                    user: {
                        ...state.user,
                        followings: state.user.followings.filter((following) => following !== action.payload),
                    },
                };
                case "LOGOUT":
                return {
                ...state,
                user: null,
                userId: null,
                };
                case "EMAIL_NOT_VERIFIED":
                return { 
                ...state, 
                error: action.payload, 
                isFetching: false 
                };
 
            default:
                return state;
    }
};

export default AuthReducer;