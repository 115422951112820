import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './addlinkbutton.css';

export default function AddLinkButton({ onClick }) {
  return (
    <button className="add-link-button" onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} /> Add Your Links
    </button>
  );
}
