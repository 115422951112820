import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './addlinkpopup.css';
import axios from 'axios';
import { axiosInstance } from '../../config';

const AddLinkPopup = ({ onClose, onLinkAdded }) => {
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [order, setOrder] = useState(0); // Add order state
  const [profileBio, setProfileBio] = useState('');
  const { user: currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axiosInstance.get(`/users/profileBio?userId=${currentUser._id}`);
        setProfileBio(res.data.profileBio);
      } catch (error) {
        console.error('Error fetching profileTitle:', error);
      }
    };

    // Poll the server every X milliseconds
    const pollingInterval = 1000; // Adjust this to your desired frequency
    const pollTimer = setInterval(fetchUserData, pollingInterval);

    // Clean up the interval on component unmount
    return () => clearInterval(pollTimer);
  }, [currentUser._id, profileBio]);

  const addLink = async (newLink) => {
    try {
      const response = await axios.post('https://linkedxio-e6f82560b206.herokuapp.com/api/addlink', newLink, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        // Reset the form and perform any necessary actions
        setUrl('');
        setTitle('');
        setOrder(0);
        onLinkAdded(); // Call the callback function to update links
        onClose(); // Close the popup or perform other actions as needed
      } else {
        console.error('Failed to add link');
      }
    } catch (error) {
      console.error('Error adding link:', error);
    }
  };

  const handleAddLink = () => {
    // Validate and add the link
    if (url && title) {
      const newLink = { url, title, order , userId: currentUser._id};
      addLink(newLink); // Call the addLink function to save the link
      console.log(currentUser._id)
      // onClose(); // You can choose to close the popup here or in the callback
    }
  };

  return (
    <div className="add-link-popup">
      <div className="popup-box">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="input-container">
          <div className="input-row">
            <label htmlFor="urlInput">Enter URL</label>
            <input
              type="text"
              id="urlInput"
              placeholder="https://example.com"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="text-input"
            />
          </div>
          <div className="input-row">
            <label htmlFor="titleInput">Title</label>
            <input
              type="text"
              id="titleInput"
              placeholder="Link Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="text-input"
            />
          </div>
          <button className="add-link-button" onClick={handleAddLink}>Add</button>
        </div>
      </div>
    </div>
  );
};

export default AddLinkPopup;
